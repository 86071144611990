import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { useDisclosure } from '@chakra-ui/react';

import { EditedJourneyContext } from 'contexts';
import { EditableTypes } from 'reducers/editableJourney';

import PassengerForm from '../PassengerForm';
import { EditableFellowPassenger } from '@airhelp/plus';

const AddedFellowPassenger = ({
  passenger,
}: {
  passenger: EditableFellowPassenger;
}) => {
  const { itineraryIndex, dispatch } = useContext(EditedJourneyContext);

  const { isOpen, onClose, onToggle } = useDisclosure();

  const form = useForm<EditableFellowPassenger>({ mode: 'onChange' });

  const onSubmit = (data: Omit<EditableFellowPassenger, 'id'>) => {
    dispatch({
      type: EditableTypes.UPDATE_FELLOW_PASSENGER,
      itineraryIndex,
      payload: {
        id: passenger.id,
        ...data,
      },
    });

    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const handleDelete = (data: EditableFellowPassenger) => {
    dispatch({
      type: EditableTypes.DELETE_FELLOW_PASSENGER,
      itineraryIndex,
      payload: { ...data },
    });

    onClose();
  };

  const handleEdit = () => {
    onToggle();
  };

  return (
    <PassengerForm
      passenger={passenger}
      handleCancel={handleCancel}
      isOpen={isOpen}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
      form={form}
      onSubmit={onSubmit}
    />
  );
};

export default AddedFellowPassenger;
