import { Itinerary } from '@airhelp/plus';
import { cityTranslations } from 'utils/journey/journey';

// format itinerary option label for Select component
export const formatItineraryOptions = (
  itineraries: Itinerary[] | undefined,
) => {
  if (!itineraries) {
    return [];
  }

  return itineraries.map((itinerary) => {
    const flights = itinerary.flights;

    const firstFlight = flights[0];
    const lastFlightIndex = flights.length - 1;
    const lastFlight = flights[lastFlightIndex];

    const departureAirport = firstFlight.departureAirport;
    const arrivalAirport = lastFlight.arrivalAirport;

    const departureAirportName = cityTranslations(departureAirport);
    const departureAirportIata = departureAirport?.iata;

    const arrivalAirportName = cityTranslations(arrivalAirport);
    const arrivalAirportIata = arrivalAirport?.iata;

    const itineraryLabel = `${departureAirportName} (${departureAirportIata}) - ${arrivalAirportName} (${arrivalAirportIata})`;

    return {
      label: itineraryLabel,
      value: itinerary.id,
    };
  });
};
