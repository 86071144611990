import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Button, Center, Flex } from '@chakra-ui/react';
import {
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from '@stripe/react-stripe-js';
import { type StripePaymentElementOptions } from '@stripe/stripe-js';

import { ErrorBadge, Loader } from 'elements';
import { type ISignupSubmitData } from 'models/User';
import { StripePaymentContext } from 'contexts';
import { BillingManagementAddress } from '@airhelp/plus';
import StripePaymentHeader from './StripePaymentHeader';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { getDashboardUrl } from 'utils/sites';
import { generatePath } from 'react-router';
import { PAGE_PATHS } from 'config/routes';
import { useTrackEvent } from 'utils/tracking/hooks';

enum STRIPE_ERRORS {
  API_CONNECTION_ERROR = 'api_connection_error',
  API_ERROR = 'api_error',
  AUTHENTICATION_ERROR = 'authentication_error',
  RATE_LIMIT_ERROR = 'rate_limit_error',
  IDEMPOTENCY_ERROR = 'idempotency_error',
  VALIDATION_ERROR = 'validation_error',
  CARD_ERROR = 'card_error',
}

interface StripeFormProps {
  clientSecret: string;
  onPaymentSubmit?: () => void;
  onPaymentFailed?: () => void;
  billingAddress?: BillingManagementAddress | null;
  isUpdate?: boolean;
}

const StripePaymentForm: React.FC<StripeFormProps> = ({
  clientSecret,
  onPaymentSubmit,
  onPaymentFailed,
  billingAddress,
  isUpdate,
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const stripe = useStripe();
  const elements = useElements();
  const { stripeElementId } = useContext(StripePaymentContext);
  const { trackCtaClick, trackPageInteractions } = useTrackEvent();

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [isUpdated, setIsUpdated] = useState(isUpdate);
  const [completeForm, setCompleteForm] = useState(false);

  const form = useForm<ISignupSubmitData>({ mode: 'onChange' });
  const { handleSubmit } = form;

  const callbackUrl = new URL(
    `${getDashboardUrl()}${generatePath(PAGE_PATHS.MY_ACCOUNT.BILLING.PROCESSING_PAGE)}`,
    window.location.origin,
  );

  useEffect(() => {
    if (elements) {
      const element = elements.getElement('payment');

      const onReady = () => {
        setIsStripeLoading(false);
      };

      element?.on('ready', onReady);
      return () => {
        element?.off('ready', onReady);
      };
    }
  }, [elements]);

  const handleNext = async () => {
    if (!stripe || !elements) {
      return;
    }

    trackCtaClick(
      'continue',
      isUpdated ? 'update payment method step 1' : 'add payment method step 1',
    );

    const { error } = await elements.submit();
    if (error) {
      trackPageInteractions(
        isUpdated ? 'error update step 1' : 'error add step 1',
        `${error} message from stripe`,
      );
      return setMessage(error.message || t('errors.something_went_wrong'));
    }
    setMessage(null);
    setShowAddressForm(true);
  };

  const onSubmit = async () => {
    if (!stripe || !elements) {
      return;
    }

    trackCtaClick(
      isUpdated ? 'update payment method' : 'save payment method',
      isUpdated ? 'update payment method step 2' : 'add payment method step 2',
    );

    setCompleteForm(true);
    setIsLoading(true);

    onPaymentSubmit?.();

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: callbackUrl.toString(),
        payment_method_data: {
          allow_redisplay: 'always',
        },
      },
    });

    setIsLoading(false);

    if (error) {
      trackPageInteractions(
        isUpdated ? 'error update step 2' : 'error add step 2',
        `${error} message from stripe`,
      );
      if (error.type === STRIPE_ERRORS.VALIDATION_ERROR) {
        return null;
      }
      if (
        error.type === STRIPE_ERRORS.API_CONNECTION_ERROR ||
        error.type === STRIPE_ERRORS.API_ERROR ||
        error.type === STRIPE_ERRORS.AUTHENTICATION_ERROR ||
        error.type === STRIPE_ERRORS.RATE_LIMIT_ERROR ||
        error.type === STRIPE_ERRORS.IDEMPOTENCY_ERROR ||
        error.type === STRIPE_ERRORS.CARD_ERROR
      ) {
        onPaymentFailed?.();
        return setMessage(error.message || t('errors.something_went_wrong'));
      }
    }
  };

  const paymentElementOptions = {
    layout: 'accordion',
  } as StripePaymentElementOptions;

  useEffect(() => {
    elements?.fetchUpdates();
  }, [stripeElementId]);

  useEffect(() => {
    if (showAddressForm) {
      setShowAddressForm(true);
      setIsUpdated(false);
    }
  }, [showAddressForm, isUpdate]);

  const getButtonLabel = () => {
    if (!showAddressForm) {
      return t('common.continue');
    }
    return isUpdate
      ? t('payment_management.update_payment_method')
      : t('payment_management.save_payment_method');
  };

  return (
    <Flex
      onSubmit={handleSubmit(onSubmit)}
      key={clientSecret}
      id="payment-form"
      justifyContent="space-between"
      flexDirection="column"
      height="calc(100% - 34px)"
      as="form"
      data-testid="checkout-widget-form"
    >
      <Box>
        <StripePaymentHeader
          showAddressForm={showAddressForm}
          isUpdate={isUpdate}
          completeForm={completeForm}
        />
        {isStripeLoading ? (
          <Center>
            <Loader />
          </Center>
        ) : null}
        <Box display={showAddressForm && !isUpdated ? 'none' : 'initial'}>
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
        </Box>
        {showAddressForm && !isUpdated ? (
          <AddressElement
            options={{
              mode: 'billing',
              defaultValues: {
                name: billingAddress?.name || null,
                address: {
                  line1: billingAddress?.addressLine1 || null,
                  line2: billingAddress?.addressLine2 || null,
                  city: billingAddress?.city || null,
                  state: billingAddress?.state || null,
                  postal_code: billingAddress?.postalCode || null,
                  country: billingAddress?.countryCode || locale,
                },
              },
            }}
          />
        ) : null}

        {message ? (
          <Box id="payment-message" mt={6}>
            <ErrorBadge>{message}</ErrorBadge>
          </Box>
        ) : null}
      </Box>
      <Button
        size="m"
        id="submit"
        mt={{ base: 4, md: 8 }}
        onClick={!showAddressForm ? () => handleNext() : handleSubmit(onSubmit)}
        whiteSpace="break-spaces"
        isLoading={isLoading}
        isDisabled={isLoading || !stripe || !elements}
        width="100%"
      >
        {getButtonLabel()}
      </Button>
    </Flex>
  );
};

export default StripePaymentForm;
