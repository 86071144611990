import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useTracking } from 'react-tracking';

import { Box, Flex, Heading, HStack, Text, VStack } from '@chakra-ui/react';

import CheckoutLayout from 'components/Layouts/CheckoutLayout';
import {
  getMembershipPlan,
  getProductTitle,
} from 'components/Membership/membershipHelper';
import { UNKNOWN_CHANNEL } from 'config/channels';
import { TrackingContext } from 'contexts';
import { Loader } from 'elements';
import AirhelpBrandWithPlanName from 'elements/AirhelpBrandWithPlanName';
import ErrorBadge from 'elements/ErrorBadge';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useStripeOrder from 'hooks/payments/useStripeOrder';
import { type Offer } from 'models/Offers';
import { formatValueToCurrency } from 'stores/preferences/currencies';
import { CheckoutEvent, useTrackCheckout } from 'utils/tracking/hooks';
import { stripeMembershipEcommerce } from 'utils/tracking/props/universal/purchase';
import { CheckoutActionTypes } from 'utils/tracking/reducers/checkoutDataReducer';

import Side from './Side';

const LabeledValue = ({
  label,
  labelColor,
  labelBold,
  value,
  valueColor,
  valueBold,
  ...props
}: {
  label: string;
  labelColor?: string;
  labelBold?: string;
  value: string;
  valueColor?: string;
  valueBold?: string;
}) => {
  return (
    <HStack {...props} width="100%" justifyContent="space-between">
      <Text
        data-testid="label"
        fontSize="md"
        color={labelColor || 'greyscale.500'}
        fontWeight={labelBold}
      >
        {label}
      </Text>
      <Text
        data-testid="value"
        fontSize="md"
        color={valueColor}
        fontWeight={valueBold}
      >
        {value}
      </Text>
    </HStack>
  );
};

interface IComponent {
  offers: Record<string, Offer>;
  renewOffer?: Offer;
}

const Succeeded: React.FC<IComponent> = ({ offers, renewOffer }) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const { checkoutDataDispatch, checkoutData } = useContext(TrackingContext);
  const { trackCheckout } = useTrackCheckout();

  const { data: userProfile, isLoading: isLoadingUserProfile } =
    useFetchMyProfile();

  const tier = searchParams.get('tier') as string | undefined;
  const renew = searchParams.get('renew') as string | undefined;
  const channel = searchParams.get('channel') || UNKNOWN_CHANNEL;
  const claimId = searchParams.get('claimId') as string | undefined;
  const brand = searchParams.get('brand') as string | undefined;
  const claimEnquiryUuid = searchParams.get('claimEnquiryUuid') as
    | string
    | undefined;
  const paymentIntentId = searchParams.get('payment_intent') as string;

  const {
    data: order,
    isLoading: isLoadingOrder,
    isError,
  } = useStripeOrder(paymentIntentId);

  const productDetails = getMembershipPlan({
    offers,
    renewOffer,
    tier,
    isManualRenew: Boolean(renew),
  });

  // track successful membership purchase
  const trackedPurchaseRef = useRef(false);
  const trackedPurchaseGA4Ref = useRef(false);
  const transactionId = order?.identifier;

  useEffect(() => {
    if (!isLoadingOrder && order && !trackedPurchaseRef.current) {
      const ecommerceData = stripeMembershipEcommerce({
        order,
        product: productDetails?.name,
        brand,
      });

      trackEvent({
        name: 'purchase',
        ...ecommerceData,
      });

      trackedPurchaseRef.current = true;
    }
  }, [order, isLoadingOrder, trackedPurchaseRef]);

  useEffect(() => {
    if (transactionId) {
      checkoutDataDispatch({
        type: CheckoutActionTypes.UPDATE,
        payload: {
          transactionId,
          productDetails,
          order,
        },
      });
    }
  }, [transactionId]);

  useEffect(() => {
    if (checkoutData.transactionId !== null && !trackedPurchaseGA4Ref.current) {
      trackCheckout(CheckoutEvent.PURCHASE);

      trackedPurchaseGA4Ref.current = true;
    }
  }, [checkoutData.transactionId]);

  const SucceedSummaryView = () => {
    if (isLoadingUserProfile || isLoadingOrder || !productDetails) {
      return (
        <Flex alignItems="center" justifyContent="center" height="100%">
          <Loader />
        </Flex>
      );
    }
    return (
      <CheckoutLayout
        side={
          userProfile ? (
            <Side
              channel={channel}
              claimId={claimId}
              claimEnquiryUuid={claimEnquiryUuid}
              userProfile={userProfile}
            />
          ) : null
        }
      >
        <Box maxWidth="586px" mb={4} data-testid="payment-success">
          <Heading variant="h2" mb={8}>
            {t('common.summary')}
          </Heading>

          <AirhelpBrandWithPlanName
            label={t('memberships.twelve_months_coverage')}
            description={getProductTitle({
              productName: productDetails.name,
            })}
          />

          {order && !isError ? (
            <VStack
              mt={8}
              pt={8}
              borderTop="1px solid"
              borderTopColor="greyscale.300"
              spacing={8}
            >
              <LabeledValue
                label={`${t('common.order_number')}:`}
                value={order.identifier}
                data-testid="order_number"
              />
              <LabeledValue
                label={`${t('pricing.duration')}:`}
                value={`12 ${t('common.months')}, ${t('pricing.renews_annually')}`}
                data-testid="duration"
              />
              <LabeledValue
                label={`${getProductTitle({ productName: productDetails.name })}:`}
                value={formatValueToCurrency(order.totalAmount, order.currency)}
                data-testid="product_amount"
              />
              {order.discountAmount ? (
                <LabeledValue
                  label={`${t('pricing.discount')}:`}
                  value={formatValueToCurrency(
                    order.discountAmount,
                    order.currency,
                  )}
                  valueColor="success.500"
                  data-testid="discount_amount"
                />
              ) : null}
              <LabeledValue
                label={`${t('pricing.total_paid')}:`}
                labelColor="primary.900"
                value={formatValueToCurrency(order.paidAmount, order.currency)}
                labelBold="medium"
                valueBold="medium"
                data-testid="paid_amount"
              />
            </VStack>
          ) : (
            <ErrorBadge mt={6} />
          )}
        </Box>
      </CheckoutLayout>
    );
  };

  return <SucceedSummaryView />;
};

export default Succeeded;
