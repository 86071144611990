import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useTracking } from 'react-tracking';

import { Box, Button, Center, Heading } from '@chakra-ui/react';
import {
  PaymentElement,
  useStripe,
  useElements,
  AddressElement,
} from '@stripe/react-stripe-js';
import {
  type StripePaymentElementChangeEvent,
  type StripePaymentElementOptions,
} from '@stripe/stripe-js';

import { acceptMarketingConsent } from 'api/user/user';
import { StripePaymentContext, TrackingContext } from 'contexts';
import { ErrorBadge, Loader } from 'elements';
import UserConsentsForm from 'elements/UserConsentsForm';
import { type ISignupSubmitData } from 'models/User';
import { CheckoutActionTypes } from 'utils/tracking/reducers/checkoutDataReducer';

enum STRIPE_ERRORS {
  API_CONNECTION_ERROR = 'api_connection_error',
  API_ERROR = 'api_error',
  AUTHENTICATION_ERROR = 'authentication_error',
  RATE_LIMIT_ERROR = 'rate_limit_error',
  IDEMPOTENCY_ERROR = 'idempotency_error',
  VALIDATION_ERROR = 'validation_error',
  CARD_ERROR = 'card_error',
}

interface StripeFormProps {
  callbackUrl: string;
  clientSecret: string;
  actionButtonName: string;
  requireTerms?: boolean;
  requireMarketing?: boolean;
  footer?: React.ReactNode;
  onPaymentSubmit?: () => void;
  onPaymentFailed?: () => void;
}

const StripeForm: React.FC<StripeFormProps> = ({
  callbackUrl,
  clientSecret,
  requireTerms,
  requireMarketing,
  actionButtonName,
  footer,
  onPaymentSubmit,
  onPaymentFailed,
}) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const { checkoutDataDispatch } = useContext(TrackingContext);
  const stripe = useStripe();
  const elements = useElements();
  const { stripeElementId } = useContext(StripePaymentContext);

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isStripeLoading, setIsStripeLoading] = useState(true);

  const form = useForm<ISignupSubmitData>({ mode: 'onChange' });
  const { handleSubmit } = form;

  useEffect(() => {
    if (elements) {
      const element = elements.getElement('payment');

      const onReady = () => {
        setIsStripeLoading(false);
        trackEvent({
          name: 'GAEvent',
          eventCategory: 'ahplus',
          eventAction: 'viewed',
          eventLabel: 'ahplus_component_stripe_widget_form_loaded',
        });
      };

      const onChange = (event: StripePaymentElementChangeEvent) => {
        const paymentMethod = event.value.type;
        checkoutDataDispatch({
          type: CheckoutActionTypes.UPDATE,
          payload: { paymentMethod },
        });
      };

      element?.on('ready', onReady);
      element?.on('change', onChange);
      return () => {
        element?.off('ready', onReady);
        element?.off('change', onChange);
      };
    }
  }, [elements]);

  const onSubmit = async (data) => {
    if (!stripe || !elements) {
      return;
    }

    trackEvent({
      name: 'GAEvent',
      eventCategory: 'ahplus',
      eventAction: 'clicked',
      eventLabel: 'ahplus_component_stripe_widget_submit_clicked',
    });

    const { marketingConsent } = data;

    setIsLoading(true);

    if (marketingConsent) {
      const [acceptMarketingConsentError] = await acceptMarketingConsent();

      if (acceptMarketingConsentError) {
        setIsLoading(false);
        return;
      }
    }

    onPaymentSubmit?.();

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: callbackUrl,
      },
    });

    setIsLoading(false);

    if (error.type === STRIPE_ERRORS.VALIDATION_ERROR) {
      return null;
    }
    if (
      error.type === STRIPE_ERRORS.API_CONNECTION_ERROR ||
      error.type === STRIPE_ERRORS.API_ERROR ||
      error.type === STRIPE_ERRORS.AUTHENTICATION_ERROR ||
      error.type === STRIPE_ERRORS.RATE_LIMIT_ERROR ||
      error.type === STRIPE_ERRORS.IDEMPOTENCY_ERROR ||
      error.type === STRIPE_ERRORS.CARD_ERROR
    ) {
      onPaymentFailed?.();
      return setMessage(error.message || t('errors.something_went_wrong'));
    }
  };

  const paymentElementOptions = {
    layout: 'accordion',
  } as StripePaymentElementOptions;

  useEffect(() => {
    elements?.fetchUpdates();
  }, [stripeElementId]);

  return (
    <form
      key={clientSecret}
      id="payment-form"
      data-testid="checkout-widget-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {isStripeLoading ? (
        <Center>
          <Loader />
        </Center>
      ) : null}
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      {!isStripeLoading && (
        <Heading variant="h5" mt={{ base: 8, lg: 14 }} mb={6}>
          {t('payment.checkout.billing_address')}
        </Heading>
      )}
      <AddressElement
        options={{
          mode: 'billing',
          display: { name: 'split' },
        }}
      />
      {message ? (
        <Box id="payment-message" mt={6}>
          <ErrorBadge>{message}</ErrorBadge>
        </Box>
      ) : null}
      {requireTerms || requireMarketing ? (
        <Box mt={4}>
          <UserConsentsForm
            form={form}
            requireTerms={requireTerms}
            requireMarketing={requireMarketing}
          />
        </Box>
      ) : null}
      <Button
        size="m"
        id="submit"
        mt={6}
        mb={4}
        onClick={handleSubmit(onSubmit)}
        whiteSpace="break-spaces"
        isLoading={isLoading}
        isDisabled={isLoading || !stripe || !elements}
        width="100%"
      >
        {actionButtonName}
      </Button>
      {footer}
    </form>
  );
};

export default StripeForm;
