import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VStack, useBreakpointValue, useToast } from '@chakra-ui/react';
import useUpdateConsents from 'hooks/api/userProfiles/useUpdateConsents/useUpdateConsents';

import { type Consents } from '@airhelp/plus';
import NotificationDetails from './NotificationDetails';
import { Ok } from '@airhelp/icons';
import { useFeatureFlags } from '@airhelp/plus/react';
import { type NotificationsConfig } from './types';

interface IComponent {
  consents: Consents;
}

const NotificationsView: React.FC<IComponent> = ({ consents }) => {
  const { data: featureFlags } = useFeatureFlags();
  const { t } = useTranslation();
  const toast = useToast();
  const updateConsents = useUpdateConsents();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const [consentsState, setConsentsState] = useState(consents);

  const [disabledState, setDisabledState] = useState({
    marketingConsent: false,
    whatsappConsent: false,
    flightNotificationsConsent: false,
  });

  // temporary value for flight notificationd until it's implemented on the backend
  const flightNotificationsState = true;

  const notificationsConfig: NotificationsConfig = {
    marketingConsent: {
      title: t('common.marketing'),
      description: t('my_account.notifications.marketing_notification'),
      state: consentsState.marketingConsent,
      name: 'marketing',
    },
    whatsappConsent: null,
    flightNotificationsConsent: featureFlags?.flight_notifications_consent
      ? {
          title: t('common.flights'),
          description: t('my_account.notifications.flights_notification'),
          state: flightNotificationsState,
          name: 'flight',
        }
      : null,
  };

  const handleToggleConsent = (
    consentKey: keyof (Consents & { flightNotifications: boolean }),
  ) => {
    if (disabledState[consentKey]) return;

    const toggledConsent = !consentsState[consentKey];

    setDisabledState((prevState) => ({
      ...prevState,
      [consentKey]: true,
    }));

    const updatedConsents = {
      ...consentsState,
      [consentKey]: toggledConsent,
    };

    updateConsents.mutate(updatedConsents, {
      onSuccess: (data) => {
        setConsentsState(data.consents);

        setDisabledState((prevState) => ({
          ...prevState,
          [consentKey]: false,
        }));
        toast({
          title: t('my_account.settings.notification_updated'),
          status: 'success',
          duration: 4000,
          position: isMobile ? 'bottom' : 'top',
          isClosable: true,
          icon: <Ok boxSize={5} alignSelf="center" color="greyscale.100" />,
        });
      },
      onError: (error) => {
        setDisabledState((prevState) => ({
          ...prevState,
          [consentKey]: false,
        }));
        toast({
          title: t('errors.something_went_wrong'),
          description: error.message,
          status: 'error',
          duration: 4000,
          isClosable: true,
        });
      },
    });
  };

  return (
    <VStack width="100%" spacing={{ base: 2, md: 4 }}>
      {Object.keys(notificationsConfig).map((key) => {
        const notificationItem = notificationsConfig[key];

        return (
          notificationItem && (
            <NotificationDetails
              key={key}
              item={notificationItem}
              isDisabled={disabledState[key]}
              onToggle={() => handleToggleConsent(key as keyof Consents)}
            />
          )
        );
      })}
    </VStack>
  );
};

export default NotificationsView;
