import type React from 'react';

import { AirPayoutPaymentPax } from '@airhelp/plus';
import { type ReducerAction as AirPayoutPaymentAction } from 'types/reducers';

export enum AirPayoutPaymentTypes {
  EDIT_PASSENGER = 'EDIT_PASSENGER',
}

export interface AirPayoutPaymentReducer {
  airPayoutJourneys: AirPayoutPaymentPax[];
}

export type Action = AirPayoutPaymentAction<
  AirPayoutPaymentTypes.EDIT_PASSENGER,
  AirPayoutPaymentPax
>;

export const airPayoutPaymentReducer: React.Reducer<
  AirPayoutPaymentReducer,
  Action
> = (state, action) => {
  const actions = {
    [AirPayoutPaymentTypes.EDIT_PASSENGER]: () => {
      const airPayoutPaymentPaxPayload = action.payload;
      const airPayoutJourneys = [
        ...state.airPayoutJourneys,
        airPayoutPaymentPaxPayload,
      ];

      const existingIndex = state.airPayoutJourneys.findIndex(
        (pax) => pax.id === airPayoutPaymentPaxPayload.id,
      );

      if (existingIndex !== -1) {
        state.airPayoutJourneys[existingIndex] = airPayoutPaymentPaxPayload;
        return state;
      }

      return {
        ...state,
        airPayoutJourneys,
      };
    },
  };

  if (typeof actions[action.type] === 'function') {
    return actions[action.type]();
  }
  throw new Error(
    `Action of type "${action.type}" is missing or is not a function`,
  );
};
