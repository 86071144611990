import React from 'react';
import { useTranslation } from 'react-i18next';

import perkLogo from 'assets/images/perks/logo-suntransfers.svg';
import { useTrackEvent } from 'utils/tracking/hooks';

import PerkDrawerContent from '../PerkDrawerContent';

const TRACKING_PROVIDER_NAME = 'suntransfers';

interface IComponent {
  imageText: string;
  imageSrcMobile: string;
}

const AirportTransfer: React.FC<IComponent> = ({
  imageText,
  imageSrcMobile,
}) => {
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();

  const benefits = [
    {
      content: t('dashboard.perks.airport_transfer.easy_booking'),
      key: 'easy_booking',
    },
    { content: t('dashboard.perks.airport_transfer.no_fees'), key: 'no_fees' },
    {
      content: t('dashboard.perks.airport_transfer.great_value'),
      key: 'great_value',
    },
    {
      content: t('dashboard.perks.airport_transfer.free_cancellation'),
      key: 'free_cancellation',
    },
    {
      content: t('dashboard.perks.airport_transfer.tolls_included'),
      key: 'tolls_included',
    },
    {
      content: t('dashboard.perks.airport_transfer.customer_service'),
      key: 'customer_service',
    },
  ];

  const siteUrl = 'https://www.tkqlhce.com/click-101115961-12613932';

  const goToSite = () => {
    trackPageInteractions(
      'go to site',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );

    window.open(siteUrl, '_blank');
  };

  return (
    <PerkDrawerContent
      heading={imageText}
      imageSrcMobile={imageSrcMobile}
      perkLogo={perkLogo}
      subHeading="dashboard.perks.airport_transfer.low_cost_transfers"
      descriptionPrimary={t(
        'dashboard.perks.airport_transfer.book_hassle_free',
      )}
      descriptionSecondary={t(
        'dashboard.perks.airport_transfer.count_on_timely_pick_ups',
      )}
      benefits={benefits}
      goToSite={goToSite}
      buttonLabel={t('dashboard.perks.airport_transfer.get_airport_transfer')}
    />
  );
};

export default AirportTransfer;
