import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link as ReactLink, useLocation } from 'react-router-dom';

import { AltArrowForward, Info } from '@airhelp/icons';
import { Button, Flex, Text } from '@chakra-ui/react';

import { useTrackEvent } from 'utils/tracking/hooks';

import { PAGE_PATHS } from 'config/routes';
import useEffectOnce from 'hooks/useEffectOnce';

const TopBannerGracePeriodPayment: React.FC = () => {
  const { t } = useTranslation();
  const { trackPageInteractions } = useTrackEvent();
  const location = useLocation();
  const pathName = location.pathname;

  const updatePaymentButton = () => {
    trackPageInteractions('payment info top banner', `${pathName} page name`);
  };

  const hideBanner = location.pathname.includes(
    PAGE_PATHS.MY_ACCOUNT.BILLING.BILLING_PAGE,
  );

  useEffectOnce(() => {
    trackPageInteractions('show', `${pathName} page name`);
  });

  if (hideBanner) {
    return null;
  }

  return (
    <Flex
      bgColor="primary.500"
      py={{ base: 0, md: 3 }}
      pl={{ base: 4, lg: 10 }}
      pr={{ base: 4, md: 4 }}
      alignItems="center"
      width="100%"
      minHeight={{ base: 12, md: 16 }}
      data-testid="grace-period-update-payment-top-banner"
    >
      <Flex
        width="100%"
        pr={{ base: 0, md: 3 }}
        alignItems="center"
        justifyContent="flex-start"
        justifySelf="flex-start"
      >
        <Info color="greyscale.100" boxSize={5} mr={3} />
        <Text
          fontSize={{ base: 'sm', md: 'md' }}
          lineHeight="short"
          color="greyscale.100"
          fontWeight="500"
          m={0}
        >
          {t('payment_management.update_your_payment_method')}
        </Text>
      </Flex>

      <Button
        variant={{ base: 'outline', md: 'subtle' }}
        border={{ base: 'none !important', md: 'inherit' }}
        size={{ base: 'sm', md: 'md' }}
        as={ReactLink}
        to={{
          pathname: generatePath(PAGE_PATHS.MY_ACCOUNT.BILLING.BILLING_PAGE),
        }}
        color={{
          base: 'greyscale.100 !important',
          md: 'primary.500 !important',
        }}
        width="auto"
        minWidth="unset !important"
        justifySelf="flex-end"
        justifyContent="flex-start"
        alignItems="center"
        iconSpacing={0}
        whiteSpace="nowrap"
        mr={{ base: -2, md: 0, lg: 3 }}
        rightIcon={
          <AltArrowForward
            boxSize={7}
            display={{ base: 'block', md: 'none' }}
            color="greyscale.100"
            mr={-3}
          />
        }
        onClick={updatePaymentButton}
        sx={{ span: { mr: 0 } }}
        data-testid="grace-period-top-banner-goto-page-btn"
        _focusVisible={{ outline: 'none' }}
        _hover={{
          bg: {
            base: 'transparent',
            md: 'greyscale.200',
          },
        }}
      >
        {t('common.update')}
      </Button>
    </Flex>
  );
};

export default TopBannerGracePeriodPayment;
