import React, { useContext, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box, Flex, HStack, Text, VStack } from '@chakra-ui/react';

import { StripePaymentContext, TrackingContext } from 'contexts';
import { Loader } from 'elements';
import AirhelpBrandWithPlanName from 'elements/AirhelpBrandWithPlanName';
import CurrencySwitcher from 'elements/CurrencySwitcher';
import useEffectOnce from 'hooks/useEffectOnce';
import { type Offer } from 'models/Offers';
import {
  type CurrencyCode,
  formatValueToCurrency,
} from 'stores/preferences/currencies';
import { CheckoutActionTypes } from 'utils/tracking/reducers/checkoutDataReducer';
import { pluralVersion } from 'utils/translations';

import BenefitsSection from './BenefitsSection';
import { type BenefitSection } from './types';
import {
  getProductTitle,
  getProductShortTitle,
  checkPromoCodeEntitlement,
} from '../../membershipHelper';
import PromoCodeInput from '../PromoCodeInput';
import { type PromoCodeValue } from '../PromoCodeInput/PromoCodeInput';
import usePromoCode from '../PromoCodeInput/usePromoCode';

interface IComponent {
  productDetails: Offer;
  callbackUrl: URL;
  claimId?: string;
  currency: CurrencyCode;
  isManualRenew: boolean;
  isUpgradeOffer?: boolean;
}

const PlanDetails = ({
  productDetails,
  claimId,
  currency,
  isManualRenew,
  isUpgradeOffer = false,
}: IComponent) => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { checkoutDataDispatch } = useContext(TrackingContext);
  const {
    paymentIntentId,
    paymentDetails,
    setPaymentDetails,
    isLoading: isStripeLoading,
  } = useContext(StripePaymentContext);

  const promoCodeEntitled = checkPromoCodeEntitlement(
    isManualRenew,
    isUpgradeOffer,
    claimId,
  );
  const promoCodePreloaded = useRef<boolean>(false);

  const form = useForm<PromoCodeValue>({ mode: 'onChange' });

  const { airPayoutPerk, airLuggagePerk } = productDetails;
  const productName = productDetails.name;

  useEffectOnce(() => {
    const productPrices = productDetails.prices[currency.toUpperCase()];
    setPaymentDetails({
      productPrice: productPrices.price,
      currency,
      discountValue: isUpgradeOffer ? productPrices.discountValue : null,
      discountedPrice: isUpgradeOffer ? productPrices.discountedPrice : null,
    });
  });

  const { isLoading, apiError, onSubmit, setPromoCodeUsed, setApiError } =
    usePromoCode({
      productName: productDetails.name,
      currency,
    });

  const airLuggagePayout = airLuggagePerk ? airLuggagePerk.config.payout : null;
  const airPayoutPayment = airPayoutPerk ? airPayoutPerk.config.payout : null;
  const airPayoutCredits = airPayoutPerk ? airPayoutPerk.credits : 0;

  const discountValue = `-${formatValueToCurrency(paymentDetails?.discountValue, currency)}`;

  const discountedPrice =
    paymentDetails?.discountedPrice &&
    formatValueToCurrency(paymentDetails.discountedPrice, currency);

  const productPrice = formatValueToCurrency(
    paymentDetails?.productPrice,
    currency,
  );

  const benefitsSectionList: BenefitSection[] = [
    {
      text: t(
        pluralVersion(
          'memberships.benefits.protection.header',
          airPayoutCredits,
          airPayoutPerk?.unlimited,
        ),
        {
          credits: airPayoutCredits,
        },
      ),
      key: 'protection',
      benefits: [
        {
          text: t('memberships.benefits.protection.airpayout', {
            amount: airPayoutPayment,
          }),
          tip: t('memberships.benefits.protection.airpayout_tip', {
            amount: airPayoutPayment,
          }),
        },
        {
          text: t('memberships.benefits.protection.airluggage', {
            amount: airLuggagePayout,
          }),
          tip: t('memberships.benefits.protection.airluggage_tip', {
            amount: airLuggagePayout,
          }),
        },
        {
          text: t('memberships.benefits.protection.lounge_access'),
          tip: t('memberships.benefits.protection.lounge_access_tip'),
        },
      ],
    },
    {
      text: t('memberships.benefits.annual_support.header'),
      key: 'annual-support',
      benefits: [
        {
          text: t('memberships.benefits.annual_support.compensation_service'),
          tip: t(
            'memberships.benefits.annual_support.compensation_service_tip',
          ),
        },
        {
          text: t('memberships.benefits.annual_support.dedicated_support'),
          tip: t('memberships.benefits.annual_support.dedicated_support_tip'),
        },
        {
          text: t('memberships.benefits.annual_support.flight_status_updates'),
          tip: t(
            'memberships.benefits.annual_support.flight_status_updates_tip',
          ),
        },
      ],
    },
    {
      text: t('memberships.benefits.travel_perks.header'),
      key: 'travel-perks',
      benefits: [
        {
          text: t('memberships.benefits.travel_perks.savings'),
          tip: t('memberships.benefits.travel_perks.savings_tip'),
        },
      ],
    },
  ];

  // loads and submits promo code from url
  useEffect(() => {
    if (
      paymentIntentId &&
      searchParams.get('promocode') &&
      !promoCodePreloaded.current
    ) {
      form.setValue('promoCode', searchParams.get('promocode') || '');
      form.handleSubmit(onSubmit)();

      promoCodePreloaded.current = true;
    }
  }, [searchParams, promoCodePreloaded, paymentIntentId, form, onSubmit]);

  // updates tracking checkout data with promo code details
  useEffect(() => {
    checkoutDataDispatch({
      type: CheckoutActionTypes.UPDATE,
      payload: { promoCodeDetails: paymentDetails },
    });
  }, [paymentDetails]);

  return (
    <Flex
      width={{ base: '100%', lg: '440px' }}
      flexDirection="column"
      flex={{ base: 'none', lg: 'none' }}
    >
      <AirhelpBrandWithPlanName
        label={t('memberships.twelve_months_coverage')}
        description={getProductTitle({ productName })}
      />
      <Box mb={8}>
        <Text fontSize="lg" fontWeight="medium" mt={12} mb={2}>
          {t('memberships.here_is_what_you_get')}
        </Text>
        <Text color="greyscale.700" fontSize="md">
          {t('memberships.here_is_what_you_get_desc')}
        </Text>
      </Box>
      <Box mb={10}>
        {benefitsSectionList.map((section: BenefitSection) => (
          <BenefitsSection
            name={section.text}
            benefits={section.benefits}
            key={section.key}
          />
        ))}
      </Box>
      {promoCodeEntitled ? (
        <PromoCodeInput
          form={form}
          onSubmit={onSubmit}
          promoCode={paymentDetails?.discountValue}
          setPromoCodeUsed={setPromoCodeUsed}
          apiError={apiError}
          setApiError={setApiError}
          isDisabled={isLoading}
          isLoading={isLoading}
        />
      ) : null}
      <VStack spacing={4} mt={7}>
        <HStack width="100%" justifyContent="space-between">
          <Text fontSize="md" color="primary.900" data-testid="product-name">
            {getProductShortTitle({ productName })}
          </Text>
          {isStripeLoading ? (
            <Loader size={20} />
          ) : (
            <Text fontSize="md">{productPrice}</Text>
          )}
        </HStack>

        {paymentDetails?.discountValue ? (
          <HStack width="100%" justifyContent="space-between">
            <Text
              fontSize="md"
              color="primary.900"
              data-testid="discount-code-label"
            >
              {`${t('pricing.discount_code')}:`}
            </Text>
            {isStripeLoading ? (
              <Loader size={20} />
            ) : (
              <Text fontSize="md" fontWeight="medium" color="success.500">
                {discountValue}
              </Text>
            )}
          </HStack>
        ) : null}
        <HStack width="100%" justifyContent="space-between">
          <Text fontSize="md" color="primary.900">
            {t('common.total')}
            {!isManualRenew && (
              <CurrencySwitcher
                render={(openModalHandler) => (
                  <>
                    <Text as="span" fontSize="md">
                      {' '}
                      (
                    </Text>
                    <Text
                      as="button"
                      color="primary.500"
                      fontSize="md"
                      onClick={openModalHandler}
                      data-testid="currency-switcher-text-button"
                    >
                      {currency}
                    </Text>
                    <Text as="span" fontSize="md">
                      )
                    </Text>
                  </>
                )}
              />
            )}
            :
          </Text>
          {isStripeLoading ? (
            <Loader size={20} />
          ) : (
            <Text fontSize="lg" fontWeight="medium">
              {paymentDetails?.discountValue ? discountedPrice : productPrice}
            </Text>
          )}
        </HStack>
      </VStack>
    </Flex>
  );
};

export default PlanDetails;
