import React, { useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Info } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import { Box, Text } from '@chakra-ui/react';
import { keyBy } from 'lodash';
import cookie from 'react-cookies';

import { notAuthorized } from 'api/ky/ky';
import AirBundlePayoutActions from 'components/Journey/AirBundle/AirBundlePayoutActions';
import PayoutForm from 'components/Journey/AirBundle/Payment/PayoutForm';
import { PAGE_PATHS } from 'config/routes';
import {
  useTriggerAirLuggageJourneys,
  useUploadAirBundleDocument,
} from '@airhelp/plus/react';
import {
  AirLuggageJourney,
  Journey,
  BankFieldsList,
  JourneyWithMeta,
  AirBundleDocumentParam,
  InsuranceType,
} from '@airhelp/plus';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import {
  AirLuggagePaymentTypes,
  airLuggagePaymentReducer,
} from 'reducers/airLuggagePaymentReducer/airLuggagePaymentReducer';
import { sentryUtils } from 'utils';

import LuggageDetails from './LuggageDetails';
import { setVeriffUrl } from 'utils/veriff';
import { TURNSTILE_COOKIE } from 'config/cookies';

interface IComponent {
  journeyListData: AirLuggageJourney[];
  onTriggerSuccessful: (airRequestId?: number) => void;
  journeyData: Journey;
}

const insuranceType = InsuranceType.AIR_LUGGAGE;

const PayoutPaymentView: React.FC<IComponent> = ({
  journeyListData,
  onTriggerSuccessful,
  journeyData,
}) => {
  const { t } = useTranslation();
  const triggerPayout = useTriggerAirLuggageJourneys();
  const uploadDocument = useUploadAirBundleDocument();
  const { userProfile } = useMyProfile();
  const { id: journeyId } = journeyData;
  const form = useForm({ mode: 'onSubmit' });
  const navigate = useNavigate();

  const initialState = { airLuggageJourneys: [] };
  const [reducerData, dispatch] = useReducer(
    airLuggagePaymentReducer,
    initialState,
  );

  const [submitError, setSubmitError] = useState<boolean>(false);
  const [airRequestId, setAirRequestId] = useState<number | undefined>();
  const [dataDetailsCollected, setDataDetailsCollected] = useState(true);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadDocumentsFinished, setUploadDocumentsFinished] =
    useState<BankFieldsList | null>(null);
  const [isValidDataDetailsForm, setIsValidDataDetailsForm] = useState(false);

  const isMultiPax = journeyListData.length > 1;
  const beneficiaryFullName = `${userProfile?.firstName} ${userProfile?.lastName}`;

  const reducerDataById = keyBy(reducerData.airLuggageJourneys, 'id');

  const paxFilled = reducerData.airLuggageJourneys.length > 0;

  useEffect(() => {
    if (airRequestId && !submitError) {
      onTriggerSuccessful(airRequestId);
    }
  }, [airRequestId, submitError]);

  const handleError = (error: unknown) => {
    setSubmitError(true);
    setUploadInProgress(false);
    sentryUtils.captureException(error);

    if (notAuthorized(error)) {
      return navigate(PAGE_PATHS.LOGIN_PAGE);
    }
  };

  const savePayout = async (data: BankFieldsList) => {
    const token = cookie.load(TURNSTILE_COOKIE);

    const params = {
      countryId: data.country?.value || '',
      journeyId,
      token,
      bankFields: { ...data, countryOfResidence: data?.residence?.value },
      airLuggageJourneys: reducerData.airLuggageJourneys,
    };

    triggerPayout.mutate(params, {
      onError: (error) => {
        handleError(error);
      },
      onSuccess: (data: JourneyWithMeta) => {
        if (data.meta?.airRequestId) {
          const airRequestId = data.meta.airRequestId;
          setVeriffUrl(
            journeyData.id,
            data.meta.sessionUrl,
            insuranceType,
            airRequestId,
          );
          setAirRequestId(airRequestId);
        } else {
          setAirRequestId(-1);
        }
        setUploadInProgress(false);
      },
    });
  };

  const onSubmit = async (data: BankFieldsList) => {
    setSubmitError(false);
    setUploadInProgress(true);
    const promises = reducerData.airLuggageJourneys.map((pax) => {
      const {
        pirReferenceFile,
        passportOrIdFile,
        boardingPassOrBookingReferenceFile,
        baggageTagFile,
        id,
      } = pax;

      if (
        !pirReferenceFile ||
        !passportOrIdFile ||
        !boardingPassOrBookingReferenceFile ||
        !baggageTagFile
      ) {
        return Promise.reject(new Error('Missing required files'));
      }

      const resourceId = journeyId;

      const pir = uploadDocument.mutateAsync({
        file: pirReferenceFile,
        insuranceType,
        documentType: AirBundleDocumentParam.PIR,
        insuranceId: id,
        resourceId,
      });

      const passport = uploadDocument.mutateAsync({
        file: passportOrIdFile,
        insuranceType,
        documentType: AirBundleDocumentParam.PASSPORT,
        insuranceId: id,
        resourceId,
      });

      const boarding = uploadDocument.mutateAsync({
        file: boardingPassOrBookingReferenceFile,
        insuranceType,
        documentType: AirBundleDocumentParam.BOARDING,
        insuranceId: id,
        resourceId,
      });

      const baggage = uploadDocument.mutateAsync({
        file: baggageTagFile,
        insuranceType,
        documentType: AirBundleDocumentParam.BAGGAGE_TAG,
        insuranceId: id,
        resourceId,
      });

      return Promise.all([pir, passport, boarding, baggage]).then(
        ([pir, passport, boarding, baggage]) => ({
          id,
          numberOfLuggages: reducerDataById[id].numberOfLuggages,
          payoutEligibleItineraryId:
            reducerDataById[id].payoutEligibleItineraryId,
          pirReference: pir,
          passportOrId: passport,
          boardingPassOrBookingReference: boarding,
          baggageTag: baggage,
        }),
      );
    });

    Promise.all(promises)
      .then((results) => {
        results.forEach((result) => {
          dispatch({
            type: AirLuggagePaymentTypes.EDIT_PASSENGER,
            payload: result,
          });
        });

        setUploadDocumentsFinished(data);
      })
      .catch((error: unknown) => {
        handleError(error);
      });
  };

  useEffect(() => {
    if (uploadDocumentsFinished) {
      savePayout({ ...uploadDocumentsFinished });
    }
  }, [uploadDocumentsFinished]);

  return (
    <>
      <Box
        backgroundColor="greyscale.300"
        borderRadius="xl"
        padding={{ base: 4, lg: 8 }}
      >
        <Text
          fontSize="lg"
          fontWeight="medium"
          mb={4}
          data-testid="air-luggage-payout-luggage-details-title"
        >
          {dataDetailsCollected
            ? t('air_luggage.luggage_details.luggage_details')
            : t('instant_cash.payout.bank_details')}
        </Text>
        {journeyListData.map(
          (airLuggageJourney) =>
            dataDetailsCollected && (
              <LuggageDetails
                key={airLuggageJourney.id}
                airLuggageJourney={airLuggageJourney}
                dispatch={dispatch}
                reducerDataById={reducerDataById}
                paxFilled={paxFilled}
                setIsValidDataDetailsForm={setIsValidDataDetailsForm}
              />
            ),
        )}
        {!dataDetailsCollected && (
          <PayoutForm
            form={form}
            onSubmit={onSubmit}
            submitError={submitError}
            uploadInProgress={uploadInProgress}
            insuranceType={insuranceType}
            beneficiaryFullName={beneficiaryFullName}
            journeyId={journeyId}
          />
        )}
      </Box>
      {isMultiPax && dataDetailsCollected ? (
        <InfoBox
          isChat
          backgroundColor="greyscale.100"
          icon={<Info color="primary.500" display="inline-flex" mb={1} />}
          variant="secondary"
          mt={{ base: 4, md: 5 }}
        >
          {t('common.fill_out_application_separately')}
        </InfoBox>
      ) : null}
      <AirBundlePayoutActions
        form={form}
        uploadInProgress={uploadInProgress}
        dataDetailsCollected={dataDetailsCollected}
        setDataDetailsCollected={setDataDetailsCollected}
        onSubmit={onSubmit}
        submitError={submitError}
        isDisabled={!paxFilled || !isValidDataDetailsForm}
        insuranceType={insuranceType}
      />
    </>
  );
};

export default PayoutPaymentView;
