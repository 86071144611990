import { useState } from 'react';

import { paymentsApi } from 'api';
import useEffectOnce from 'hooks/useEffectOnce';
import { type CurrencyCode } from 'stores/preferences/currencies';
import { type HttpError } from 'types/api';
import { ProductName } from '@airhelp/plus';

const { fetchStripeClientSecret } = paymentsApi;

const useClientSecret = ({
  email,
  productName,
  currency,
  recurring = false,
  upgrade = false,
  channel,
  locale,
  tripUuid,
}: {
  email: string;
  productName?: ProductName;
  currency: CurrencyCode;
  recurring?: boolean;
  channel: string;
  locale: string;
  upgrade?: boolean;
  tripUuid?: string;
}) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [pending, setPending] = useState(false);
  const [error, setError] = useState<HttpError | null>(null);

  useEffectOnce(() => {
    if (!productName) {
      return;
    }

    const fetch = async () => {
      setPending(true);
      const [error, data] = await fetchStripeClientSecret({
        email,
        productName,
        currency,
        recurring,
        channel,
        locale,
        tripUuid,
        upgrade,
      });

      if (error) {
        setError(error);
        setPending(false);
      }
      setClientSecret(data?.clientSecret);
      setPending(false);
    };

    fetch();
  });

  return { clientSecret, pending, error };
};

export default useClientSecret;
