import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useLocation } from 'react-router-dom';
import { useTracking } from 'react-tracking';

import {
  Crossroad,
  Panels,
  PanelsFilled,
  Chair,
  ChairFilled,
  Shield,
  ShieldFilled,
} from '@airhelp/icons';
import { Box, UnorderedList } from '@chakra-ui/react';

import { PAGE_PATHS } from 'config/routes';
import { TrackingContext } from 'contexts';
import { useTrackEvent } from 'utils/tracking/hooks';

import NavItem from './NavItem';
import { type NavBarItem } from './NavItem/NavItem';
import useFetchLoungePasses from 'hooks/api/userProfiles/useFetchLoungePasses/useFetchLoungePasses';
import { UserPolicy } from '@airhelp/plus';

enum NavigationElements {
  HOME = 'Home',
  JOURNEYS = 'Journeys',
  CLAIMS = 'Claims',
  LOUNGES = 'Lounges',
}

interface IComponent {
  userProfilePolicy?: UserPolicy;
}

const NavigationBar: React.FC<IComponent> = ({ userProfilePolicy }) => {
  const { trackEvent } = useTracking();
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const { subPageType } = useContext(TrackingContext);
  const location = useLocation();
  const { data: loungePasses } = useFetchLoungePasses();
  const { dashboard } = userProfilePolicy || {};

  const upsellEnabled = dashboard?.upsellEnabled;
  const loungesEnabled =
    dashboard?.loungesPerkEnabled || (loungePasses || []).length > 0;

  const trackClickEvent = (element: NavigationElements, label: string) => {
    trackEvent({
      element,
      eventCategory: 'ahplus',
      eventAction: 'clicked',
      eventLabel: 'ahplus_dashboard_navigation_clicked',
    });
    trackCtaClick(label, subPageType);
  };

  const navBarItems: NavBarItem[] = [
    {
      label: t('navigation.home'),
      icon: Panels,
      hoverIcon: PanelsFilled,
      route: generatePath(PAGE_PATHS.HOME_PAGE),
      action: () => trackClickEvent(NavigationElements.HOME, 'home section'),
      dataTestId: 'home',
    },
    {
      label: t('navigation.trips'),
      icon: Crossroad,
      hoverIcon: Crossroad,
      route: generatePath(PAGE_PATHS.JOURNEYS_PAGE),
      action: () =>
        trackClickEvent(NavigationElements.JOURNEYS, 'journeys section'),
      dataTestId: 'journeys',
    },
    {
      label: t('navigation.claims'),
      icon: Shield,
      hoverIcon: ShieldFilled,
      route: generatePath(PAGE_PATHS.CLAIMS_PAGE),
      action: () =>
        trackClickEvent(NavigationElements.CLAIMS, 'claims section'),
      dataTestId: 'claims',
    },
  ];

  if (loungesEnabled || upsellEnabled) {
    navBarItems.push({
      label: t('navigation.lounges'),
      icon: Chair,
      hoverIcon: ChairFilled,
      route: generatePath(PAGE_PATHS.LOUNGES_PAGE),
      action: () =>
        trackClickEvent(NavigationElements.LOUNGES, 'lounges section'),
      dataTestId: 'lounges',
    });
  }

  return (
    <Box
      as="nav"
      backgroundColor="greyscale.100"
      height={{ base: '78px', lg: '100%' }}
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      paddingTop={{ base: 0, lg: 0 }}
      textAlign={{ base: 'center', lg: 'left' }}
      borderTop={{ base: '1px solid', lg: 0 }}
      borderTopColor="greyscale.400"
      width={{ base: '100%', lg: '278px' }}
      justifyContent="inherit"
      boxShadow="light"
      zIndex={{ base: '1000', md: '1' }}
    >
      <UnorderedList
        listStyleType="none"
        ms={0}
        display="flex"
        h="100%"
        m={{ base: 'auto', lg: '160px 0 0 0' }}
        p={{ base: '10px 11px', lg: 0 }}
        maxW={{ base: '92%', sm: '400px', md: '510px', lg: 'initial' }}
        alignItems={{ base: 'center', lg: 'initial' }}
        justifyContent={{ base: 'space-between', lg: 'flex-start' }}
        flexDirection={{ base: 'initial', lg: 'column' }}
        overflowX={{ base: 'scroll', lg: 'initial' }}
        gap={{ base: 0, lg: 8 }}
        css={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {navBarItems.map((item) => {
          return (
            <NavItem item={item} key={`${item.label}.${location.pathname}`} />
          );
        })}
      </UnorderedList>
    </Box>
  );
};

export default NavigationBar;
