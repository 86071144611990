import React from 'react';
import { useTranslation } from 'react-i18next';

import { InfoOutline, PlusMath } from '@airhelp/icons';
import {
  Button,
  HStack,
  Heading,
  IconButton,
  Spacer,
  Tooltip,
  useDisclosure,
  useBreakpointValue,
  Link,
} from '@chakra-ui/react';

import chakraStyles from 'utils/styles/chakra';
import { getWebappNewClaimUrl } from 'utils/sites';
import { LinkTarget } from 'types/utils';

interface IComponent {
  heading: string;
  tooltip?: string;
  onSeeAllClick?: () => void;
  onAddClaimClick?: () => void;
}

const Header: React.FC<IComponent> = ({
  heading,
  tooltip,
  onSeeAllClick,
  onAddClaimClick,
}) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <HStack width="100%">
      <Heading variant="h5">{heading}</Heading>
      {tooltip ? (
        <Tooltip
          label={tooltip}
          isOpen={isOpen}
          hasArrow
          arrowPadding={6}
          bgColor="greyscale.800"
          borderRadius="md"
          p={4}
        >
          <InfoOutline
            color="primary.500"
            ml={1}
            boxSize={4}
            h="auto"
            verticalAlign="text-top"
            cursor="pointer"
            onMouseEnter={onOpen}
            onMouseLeave={onClose}
            onClick={onToggle}
          />
        </Tooltip>
      ) : null}
      <Spacer />
      {onSeeAllClick && !isMobile ? (
        <Button
          size="md"
          variant="secondary"
          onClick={onSeeAllClick}
          data-testid="btn-see-all-claims"
        >
          {t('common.see_all')}
        </Button>
      ) : null}
      {onAddClaimClick ? (
        isMobile ? (
          <IconButton
            as={Link}
            icon={<PlusMath boxSize="16px !important" />}
            onClick={onAddClaimClick}
            isRound
            aria-label="add claim"
            target={LinkTarget.SELF}
            isExternal
            href={getWebappNewClaimUrl()}
            size="sm"
            _hover={{ bgColor: 'primary.600' }}
            data-testid="add-claim-round-button"
          />
        ) : (
          <Button
            as={Link}
            size="md"
            onClick={onAddClaimClick}
            data-testid="add-claim-button"
            href={getWebappNewClaimUrl()}
            target={LinkTarget.SELF}
            isExternal
            {...chakraStyles.buttonLinkStyles}
          >
            <PlusMath mr={2} fontSize="lg" />
            {t('claims.add_claim')}
          </Button>
        )
      ) : null}
    </HStack>
  );
};

export default Header;
