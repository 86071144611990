import { Expose } from 'class-transformer';

export interface IBenefits {
  airLuggagePayout: number | null;
  airLuggageMaxBags: number | null;
  airLuggageTokens: number | null;
  airLuggageUnlimited: boolean | null;
  airLuggageIncluded: boolean | null;
  airPayoutPayout: number;
  airPayoutTokens: number;
  airPayoutUnlimited: boolean;
  airPayoutIncluded: boolean;
  loungeAccessEnabled: boolean;
  loungeAccessUnlimited: boolean;
  loungeAccessTokens: number;
  departureThresholdHours: number;
  journeyLoungeAccessEnabled: boolean;
  journeyLoungeAccessTokens: number;
  journeyLoungeAccessUnlimited: boolean;
  journeyLoungeAccessCommunicatedDepartureThresholdHours: number;
  required24hDepartureTime: boolean;
  freeCompensationIncluded: boolean;
}

export class Benefits implements IBenefits {
  @Expose() airLuggagePayout: number;
  @Expose() airLuggageMaxBags: number;
  @Expose() airLuggageTokens: number;
  @Expose() airLuggageUnlimited: boolean;
  @Expose() airLuggageIncluded: boolean;
  @Expose() airPayoutPayout: number;
  @Expose() airPayoutTokens: number;
  @Expose() airPayoutUnlimited: boolean;
  @Expose() airPayoutIncluded: boolean;
  @Expose() loungeAccessEnabled: boolean;
  @Expose() loungeAccessUnlimited: boolean;
  @Expose() loungeAccessTokens: number;
  @Expose() journeyLoungeAccessEnabled: boolean;
  @Expose() journeyLoungeAccessTokens: number;
  @Expose() journeyLoungeAccessUnlimited: boolean;
  @Expose() journeyLoungeAccessCommunicatedDepartureThresholdHours: number;
  @Expose() loungeAccessCommunicatedDepartureThresholdHours: number;
  @Expose() departureThresholdHours: number;
  @Expose() freeCompensationIncluded: boolean;

  @Expose()
  get required24hDepartureTime(): boolean {
    return this.departureThresholdHours == 24;
  }

  @Expose()
  get loungesIncluded(): boolean {
    return this.loungeAccessEnabled || this.journeyLoungeAccessEnabled;
  }
}
