import { Expose, Type, plainToClass } from 'class-transformer';
import sha256 from 'crypto-js/sha256';

import { UserPolicy } from './UserPolicy';
import { Consents } from './userProfile/Consents';

export interface IUserProfile {
  id: number;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  email: string;
  consents: Consents;
  policy: UserPolicy;
}

export class UserProfile {
  @Expose() id: number;
  @Expose() firstName: string | null;
  @Expose() lastName: string | null;
  @Expose() phoneNumber: string | null;
  @Expose() email: string;

  @Expose()
  @Type(() => Consents)
  consents: Consents;

  @Expose()
  @Type(() => UserPolicy)
  policy: UserPolicy;

  @Expose()
  get hashedEmail(): string {
    return sha256(this.email).toString();
  }
}

export function transformToUserProfileClass(userProfile: unknown): UserProfile {
  return plainToClass(UserProfile, userProfile);
}

export default UserProfile;
