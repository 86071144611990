import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import Section from 'components/MyAccountView/BenefitsView/Section';
import { Loader } from 'elements';
import { Delete, Info } from '@airhelp/icons';
import { PAGE_PATHS } from 'config/routes';
import { useTranslation } from 'react-i18next';

interface IComponent {
  title: string;
  description: string;
  isError?: boolean;
  isProcessing?: boolean;
}

const ProcessingComponent: React.FC<IComponent> = ({
  title,
  description,
  isError,
  isProcessing,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getStatusContent = () => {
    if (isProcessing) {
      return <Loader size={48} dataTestId="processing-payment-checking" />;
    }

    if (isError) {
      return (
        <Flex
          justifyContent="center"
          alignItems="center"
          width={18}
          height={18}
          borderRadius="full"
          bg="danger.100"
          data-testid="processing-payment-error"
        >
          <Delete boxSize={10} color="danger.600" />
        </Flex>
      );
    }

    return (
      <Flex
        justifyContent="center"
        alignItems="center"
        width={18}
        height={18}
        borderRadius="full"
        bg="greyscale.100"
        data-testid="processing-payment-longer-checking"
      >
        <Info boxSize={10} color="primary.400" />
      </Flex>
    );
  };

  return (
    <Section header={t('payment_management.payment_methods')}>
      <VStack
        w={{ base: '100%', md: '67%' }}
        mt={{ base: 4, md: 0 }}
        borderWidth={{ base: '0', md: '1px' }}
        borderColor="greyscale.400"
        borderRadius="lg"
        p={{ base: 0, md: 4 }}
        alignItems="flex-start"
        spacing={{ base: 4, md: 5 }}
      >
        <Flex
          backgroundColor={{ base: 'greyscale.100', md: 'greyscale.300' }}
          width="100%"
          padding={{ base: 0, md: 4 }}
          borderRadius={{ base: 'none', md: 'xl' }}
          justifyContent="center"
        >
          <Flex
            borderRadius="3xl"
            alignItems="center"
            p={6}
            maxWidth={{ base: '342px', sm: '312px' }}
            maxHeight={{ base: '100%', sm: '184px' }}
            minHeight={{ base: 'unset', sm: '184px' }}
            minWidth={{ base: 'unset', sm: '312px' }}
            width="100%"
            height="100%"
          >
            <Flex width="100%" justifyContent="center">
              {getStatusContent()}
            </Flex>
          </Flex>
        </Flex>
        <Box textAlign="center" width="100%">
          <Text size="md" fontWeight="medium">
            {title}
          </Text>
          <Text size="sm" color="greyscale.600">
            {description}
          </Text>
          {isError || !isProcessing ? (
            <Button
              size="m"
              onClick={() =>
                navigate(PAGE_PATHS.MY_ACCOUNT.BILLING.BILLING_PAGE)
              }
              mt={3}
              width={{ base: '100%', lg: 'initial' }}
              data-testid="btn-try-again"
            >
              {isError ? t('payment.try_again') : t('common.back_to_payment')}
            </Button>
          ) : null}
        </Box>
      </VStack>
    </Section>
  );
};

export default ProcessingComponent;
