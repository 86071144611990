import React from 'react';

import { Flex } from '@chakra-ui/react';

import TopBannerEssentialUpgrade from 'components/HomeView/TopBannerEssentialUpgrade';
import TopBannerGracePeriodPayment from 'components/HomeView/TopBannerGracePeriodPayment';

interface IComponent {
  showOnGracePeriodPaymentBanner: boolean;
  showTopBannerEssentialUpgrade: boolean;
}

const TopBanners: React.FC<IComponent> = ({
  showTopBannerEssentialUpgrade,
  showOnGracePeriodPaymentBanner,
}) => {
  return (
    <Flex flexDirection="column" position="fixed" zIndex="11" width="100%">
      {showTopBannerEssentialUpgrade ? <TopBannerEssentialUpgrade /> : null}
      {showOnGracePeriodPaymentBanner ? <TopBannerGracePeriodPayment /> : null}
    </Flex>
  );
};

export default TopBanners;
