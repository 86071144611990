import React from 'react';
import { useTranslation } from 'react-i18next';

import { Idea } from '@airhelp/icons';
import { Collapsible } from '@airhelp/react';
import {
  Box,
  Circle,
  HStack,
  List,
  ListItem,
  Spacer,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Card } from 'elements';
import { MIN_CARD_WIDTH } from 'config/lounge';

interface IHowItWorksStep {
  id: number;
  title: string;
}

interface IComponent {
  onToggle: (isToggle: boolean) => void;
}

const HowItWorks: React.FC<IComponent> = ({ onToggle }) => {
  const { t } = useTranslation();

  const howItWorksSteps: IHowItWorksStep[] = [
    {
      id: 1,
      title: t('lounges.how_it_works.step_1'),
    },
    {
      id: 2,
      title: t('lounges.how_it_works.step_2'),
    },
    {
      id: 3,
      title: t('lounges.how_it_works.step_3'),
    },
  ];

  const isMobile = useBreakpointValue({ base: true, md: false });

  const howItWorksStepsList = (
    <>
      <List spacing={8} mt={8}>
        {howItWorksSteps.map((item) => (
          <ListItem
            key={`how-it-works-${item.id}`}
            display="flex"
            alignItems="flex-start"
          >
            <Circle size={6} bg="greyscale.400" mr={4}>
              <Text color="primary.900" fontSize="sm" fontWeight="medium">
                {item.id}
              </Text>
            </Circle>
            <Text fontSize="md">{item.title}</Text>
          </ListItem>
        ))}
      </List>
      <Spacer />
      <Box
        w="100%"
        bgColor="greyscale.300"
        borderRadius="md"
        px={4}
        py={3}
        mt={{ base: 8, md: 5 }}
      >
        <HStack>
          <Box>
            <Text fontSize="sm" fontWeight="medium">
              {t('lounges.how_it_works.no_time')}
            </Text>
            <Text fontSize="sm">
              {t('lounges.how_it_works.3_months_to_enjoy')}
            </Text>
          </Box>
          <Spacer />
          <Idea color="danger.500" alignSelf="flex-start" />
        </HStack>
      </Box>
    </>
  );

  if (isMobile) {
    return (
      <Collapsible
        title={t('lounges.how_it_works.header')}
        borderRadius="lg"
        onToggle={onToggle}
        data-testid="lounges-how-it-works"
      >
        {howItWorksStepsList}
      </Collapsible>
    );
  }
  return (
    <Card
      py={10}
      px={{ base: 5, lg: 8 }}
      data-testid="lounges-how-it-works"
      flex={{ base: 'unset', lg: '1' }}
      minWidth={{ base: '100%', lg: MIN_CARD_WIDTH }}
    >
      <Text fontSize="lg" fontWeight="bold">
        {t('lounges.how_it_works.header')}
      </Text>
      {howItWorksStepsList}
    </Card>
  );
};
export default HowItWorks;
