import React from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';

import { PriceTag } from '@airhelp/icons';
import {
  Flex,
  VStack,
  Image,
  Text,
  useBreakpointValue,
  HStack,
  Box,
} from '@chakra-ui/react';

import { useTrackEvent, useTrackOnce } from 'utils/tracking/hooks';
import { ServiceProvider } from 'types/perks';
import { MembershipTier } from '@airhelp/plus';

interface IComponent {
  imageSrc: string;
  imageText: string;
  description: string;
  logoSrc: string;
  onClick: () => void;
  discount: number | null;
  service: string;
  provider: ServiceProvider;
  membershipTier?: MembershipTier;
}

const PerkBanner: React.FC<IComponent> = ({
  imageSrc,
  imageText,
  description,
  logoSrc,
  onClick,
  discount,
  service,
  provider,
}) => {
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { trackPageInteractions } = useTrackEvent();

  const onBannerClick = () => {
    trackPageInteractions('banner open', provider, 'travel service banner');

    onClick();
  };

  const visibilityTreshhold = useBreakpointValue({ base: 0.75, lg: 0.5 });

  const { ref, inView } = useInView({
    threshold: visibilityTreshhold,
  });

  useTrackOnce(
    (setTracked) => {
      if (inView) {
        trackPageInteractions(
          'banner visible',
          provider,
          'travel service banner',
        );

        setTracked();
      }
    },
    [inView],
  );

  return (
    <Box flex="1">
      <Flex
        bgColor="greyscale.100"
        p={{ base: 0, md: 4 }}
        position="relative"
        gap={4}
        borderRadius="xl"
        onClick={onBannerClick}
        minWidth={{ base: '148px', md: '300px' }}
        data-testid={`${service}-banner`}
        cursor="pointer"
        ref={ref}
        height="100%"
      >
        <Image
          src={imageSrc}
          height="156px"
          objectFit="cover"
          objectPosition="center"
          overflow="hidden"
          minWidth={{ base: '100%', md: '148px' }}
          maxWidth={{ base: '100%', md: '148px' }}
          borderRadius="xl"
        />
        {discount ? (
          <HStack
            height="26px"
            bgColor="success.500"
            position="absolute"
            bottom={{ base: 'unset', md: 6 }}
            top={{ base: 4, md: 'unset' }}
            left={{ base: 4, md: 6 }}
            p={1}
            borderRadius="sm"
            spacing="6px"
            data-testid={`${service}-banner-discount-tag`}
          >
            <PriceTag color="white" boxSize={3} />
            <Text color="greyscale.100" fontSize="xs" fontWeight="bold">
              {t('dashboard.perks.percentage_off', { percentage: discount })}
            </Text>
          </HStack>
        ) : null}
        {isMobile ? (
          <Text
            color="greyscale.100"
            fontSize="sm"
            fontWeight="medium"
            position="absolute"
            bottom={4}
            left={4}
            mr={1}
          >
            {imageText}
          </Text>
        ) : (
          <VStack alignItems="flex-start" justifyContent="space-between" pr={2}>
            <Image src={logoSrc} h={4} />
            <VStack alignItems="flex-start" spacing={0}>
              <Text fontWeight="medium" fontSize="md">
                {imageText}
              </Text>
              <Text fontSize="sm" color="greyscale.600">
                {description}
              </Text>
            </VStack>
          </VStack>
        )}
      </Flex>
    </Box>
  );
};

export default PerkBanner;
