import React, { useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Info } from '@airhelp/icons';
import { InfoBox } from '@airhelp/react';
import { Box, Text } from '@chakra-ui/react';
import { keyBy } from 'lodash';
import cookie from 'react-cookies';

import { notAuthorized } from 'api/ky/ky';
import AirBundlePayoutActions from 'components/Journey/AirBundle/AirBundlePayoutActions';
import PayoutForm from 'components/Journey/AirBundle/Payment/PayoutForm';
import { PAGE_PATHS } from 'config/routes';
import {
  useTriggerAirPayoutJourneys,
  useUploadAirBundleDocument,
} from '@airhelp/plus/react';

import {
  airPayoutPaymentReducer,
  AirPayoutPaymentTypes,
} from 'reducers/airPayoutPaymentReducer/airPayoutPaymentReducer';
import { sentryUtils } from 'utils';

import DocumentsDetails from './DocumentsDetails';
import {
  AirPayoutJourney,
  Journey,
  BankFieldsList,
  AirBundleDocumentParam,
  InsuranceType,
  JourneyWithMeta,
} from '@airhelp/plus';
import { setVeriffUrl } from 'utils/veriff';
import { TURNSTILE_COOKIE } from 'config/cookies';

const insuranceType = InsuranceType.AIR_PAYOUT;

interface IComponent {
  journeyListData: AirPayoutJourney[];
  onTriggerSuccessful: (airRequestId?: number) => void;
  journeyData: Journey;
}

const PayoutPaymentView: React.FC<IComponent> = ({
  journeyListData,
  onTriggerSuccessful,
  journeyData,
}) => {
  const { t } = useTranslation();
  const triggerPayout = useTriggerAirPayoutJourneys();
  const uploadDocument = useUploadAirBundleDocument();
  const navigate = useNavigate();
  const { id: journeyId } = journeyData;

  const form = useForm({ mode: 'onSubmit' });

  const initialState = { airPayoutJourneys: [] };
  const [reducerData, dispatch] = useReducer(
    airPayoutPaymentReducer,
    initialState,
  );

  const [submitError, setSubmitError] = useState<boolean>(false);
  const [airRequestId, setAirRequestId] = useState<number | undefined>();
  const [dataDetailsCollected, setDataDetailsCollected] = useState(true);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadDocumentsFinished, setUploadDocumentsFinished] =
    useState<BankFieldsList | null>(null);
  const [isValidDataDetailsForm, setIsValidDataDetailsForm] = useState(false);

  const isMultiPax = journeyListData.length > 1;
  const beneficiaryFullName = journeyListData[0]?.name;

  const reducerDataById = keyBy(reducerData.airPayoutJourneys, 'id');

  const availablePayoutRequestedPax = journeyListData.filter(
    (pax) => pax.payoutAllowed,
  ).length;
  const paxFilled =
    reducerData.airPayoutJourneys.length === availablePayoutRequestedPax;

  useEffect(() => {
    if (airRequestId && !submitError) {
      onTriggerSuccessful(airRequestId);
    }
  }, [airRequestId, submitError]);

  const handleError = (error: unknown) => {
    setSubmitError(true);
    setUploadInProgress(false);
    sentryUtils.captureException(error);

    if (notAuthorized(error)) {
      return navigate(PAGE_PATHS.LOGIN_PAGE);
    }
  };

  const savePayout = async (data: BankFieldsList) => {
    const token = cookie.load(TURNSTILE_COOKIE);
    const params = {
      countryId: data.country?.value || '',
      journeyId,
      token,
      bankFields: { ...data, countryOfResidence: data?.residence?.value },
      airPayoutJourneys: reducerData.airPayoutJourneys,
    };

    triggerPayout.mutate(params, {
      onError: (error) => {
        handleError(error);
      },
      onSuccess: (data: JourneyWithMeta) => {
        if (data.meta?.airRequestId) {
          const airRequestId = data.meta.airRequestId;
          setVeriffUrl(
            journeyData.id,
            data.meta.sessionUrl,
            insuranceType,
            airRequestId,
          );
          setAirRequestId(airRequestId);
        } else {
          setAirRequestId(-1);
        }
        setUploadInProgress(false);
      },
    });
  };

  const onSubmit = async (data: BankFieldsList) => {
    setSubmitError(false);
    setUploadInProgress(true);

    const promises = reducerData.airPayoutJourneys.map((pax) => {
      const { passportOrIdFile, boardingPassOrBookingReferenceFile, id } = pax;
      const resourceId = journeyId;

      if (!passportOrIdFile || !boardingPassOrBookingReferenceFile) {
        return Promise.reject(new Error('Missing required files'));
      }

      const passport = uploadDocument.mutateAsync({
        file: passportOrIdFile,
        insuranceType,
        documentType: AirBundleDocumentParam.PASSPORT,
        insuranceId: id,
        resourceId,
      });

      const boarding = uploadDocument.mutateAsync({
        file: boardingPassOrBookingReferenceFile,
        insuranceType,
        documentType: AirBundleDocumentParam.BOARDING,
        insuranceId: id,
        resourceId,
      });

      return Promise.all([passport, boarding]).then(([passport, boarding]) => ({
        id,
        passportOrId: passport,
        boardingPassOrBookingReference: boarding,
      }));
    });

    Promise.all(promises)
      .then((results) => {
        results.forEach((result) => {
          dispatch({
            type: AirPayoutPaymentTypes.EDIT_PASSENGER,
            payload: result,
          });
        });

        setUploadDocumentsFinished(data);
      })
      .catch((error: unknown) => {
        handleError(error);
      });
  };

  useEffect(() => {
    if (uploadDocumentsFinished) {
      savePayout({ ...uploadDocumentsFinished });
    }
  }, [uploadDocumentsFinished]);

  return (
    <>
      <Box
        backgroundColor="greyscale.300"
        borderRadius="xl"
        padding={{ base: 4, lg: 8 }}
      >
        <Text
          fontSize="lg"
          fontWeight="medium"
          mb={4}
          data-testid="air-bundle-payout-title"
        >
          {dataDetailsCollected
            ? t('common.documents')
            : t('instant_cash.payout.bank_details')}
        </Text>

        {journeyListData.map(
          (airPayoutJourney) =>
            dataDetailsCollected && (
              <DocumentsDetails
                key={airPayoutJourney.id}
                airPayoutJourney={airPayoutJourney}
                dispatch={dispatch}
                reducerDataById={reducerDataById}
                paxFilled={paxFilled}
                setIsValidDataDetailsForm={setIsValidDataDetailsForm}
              />
            ),
        )}
        {!dataDetailsCollected && (
          <PayoutForm
            form={form}
            onSubmit={onSubmit}
            submitError={submitError}
            uploadInProgress={uploadInProgress}
            beneficiaryFullName={beneficiaryFullName}
            journeyId={journeyId}
            insuranceType={insuranceType}
          />
        )}
      </Box>
      {isMultiPax && dataDetailsCollected ? (
        <InfoBox
          isChat
          backgroundColor="greyscale.100"
          icon={<Info color="primary.500" display="inline-flex" mb={1} />}
          variant="secondary"
          mt={{ base: 4, md: 5 }}
        >
          {t('common.fill_out_application_separately')}
        </InfoBox>
      ) : null}
      <AirBundlePayoutActions
        form={form}
        uploadInProgress={uploadInProgress}
        dataDetailsCollected={dataDetailsCollected}
        setDataDetailsCollected={setDataDetailsCollected}
        onSubmit={onSubmit}
        submitError={submitError}
        isDisabled={!paxFilled || !isValidDataDetailsForm}
        insuranceType={insuranceType}
      />
    </>
  );
};

export default PayoutPaymentView;
