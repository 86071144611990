import { theme } from '@airhelp/chakra-theme';
import { extendTheme } from '@chakra-ui/react';

// TODO: move most of those configs into react-ui-components
const appCustomTheme = {
  breakpoints: {
    sm: '480px',
    md: '768px',
    lg: '1024px',
    xlg: '1280px',
    xl: '1440px',
    '2xl': '1536px',
  },
  styles: {
    global: {
      body: {
        color: 'primary.900',
      },
    },
  },
  components: {
    Modal: {
      baseStyle: {
        // Make sure that modal does not vertically extend screen size
        maxH: '100%',
        dialogContainer: {
          // Fix scrolling behaviour on mobile
          flexWrap: 'wrap',
        },
      },
    },
    Tooltip: {
      baseStyle: {
        zIndex: 80,
      },
    },
  },
};

const appTheme = extendTheme(theme, appCustomTheme);

export default appTheme;
