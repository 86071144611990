import { useOffers } from '@airhelp/plus/react';
import { useMemo } from 'react';
import { plainToClass } from 'class-transformer';
import { UseQueryResult } from '@tanstack/react-query';
import { keyBy } from 'lodash';
import { transformToOfferClass, Offer, Offers } from 'models/Offers';

type UseOffersWithComputedDataProps = Parameters<typeof useOffers>[0];
export type OffersData = NonNullable<
  UseQueryResult<ReturnType<typeof useOffers>['data']>['data']
>;

export const transformOffers = (data: OffersData): Offers => {
  const offers: Record<string, Offer> = keyBy(
    data.offers.map((offer) => transformToOfferClass(offer)),
    'name',
  );

  const renewOffer: Offer | undefined = transformToOfferClass(
    data?.renewOffers?.[0],
  );

  const upgradeOffer: Offer | undefined = transformToOfferClass(
    data?.upgradeOffers?.[0],
  );

  return plainToClass(Offers, { offers, renewOffer, upgradeOffer });
};

export function useComputedOffers(props: UseOffersWithComputedDataProps) {
  const { isLoading, data, isError } = useOffers({
    ...props,
    notifyOnChangeProps: ['data', 'isError', 'isLoading'],
  });

  return {
    isError,
    isLoading,
    data: useMemo(() => {
      if (data) {
        return transformOffers(data);
      }
      return data;
    }, [data]),
  };
}
