import React from 'react';
import { Outlet, createBrowserRouter, RouterProvider } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import AuthenticationLayout from 'components/Layouts/AuthenticationLayout/AuthenticationLayout';
import AppInitializer from 'components/Routes/AppInitializer';
import DefaultPageRouteLayout from 'components/Routes/DefaultPageRouteLayout';
import {
  ClaimsPage,
  HomePage,
  JourneyPage,
  LoginPage,
  LoungeFinderPage,
  LoungesPage,
  MembershipsPage,
  MyAccountPage,
  NotFoundPage,
  RedirectPage,
  SetPasswordPage,
  ResetPasswordPage,
  SignupPage,
  TripRegistrationPage,
  UpsellPage,
  ErrorPage,
  JourneysPage,
} from 'pages';
import * as JourneyPages from 'pages/Journey';
import * as LoungeFinderPages from 'pages/LoungeFinder';
import * as LoungesPages from 'pages/Lounges';
import * as MembershipsPages from 'pages/Memberships';
import * as MembershipsBuyPages from 'pages/Memberships/Buy';
import * as MyAccountPages from 'pages/MyAccount';
import * as TripsPages from 'pages/Trips';
import * as UpsellPages from 'pages/Upsell';
import * as UpsellTripsPages from 'pages/Upsell/Trips';
import ScrollToTop from 'utils/scrollToTop';
import { InsuranceType } from '@airhelp/plus';
import NewFlightsPage from 'pages/Flights/NewFlightsPage';
import AirBundlePage from 'pages/Journey/AirBundlePages/AirBundlePage';

let routerOptions: { basename?: string } = {
  basename: import.meta.env.BASE_URL,
};

const hostname = window.location.hostname;
if (hostname.includes('my-sta.airhelp.') || hostname.includes('my.airhelp.')) {
  routerOptions = {};
}

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const newRouter = sentryCreateBrowserRouter(
  [
    {
      element: (
        <AppInitializer>
          <Outlet />
          <ScrollToTop />
        </AppInitializer>
      ),
      children: [
        {
          id: 'add-flights',
          path: 'flights/new/*',
          element: <NewFlightsPage />,
        },
        {
          path: 'lounge-finder',
          element: <LoungeFinderPage />,
          children: [
            {
              index: true,
              element: <LoungeFinderPages.IndexPage />,
            },
            {
              path: 'airport/:airport_code',
              element: <LoungeFinderPages.AirportPage />,
            },
            {
              path: 'airport/:airport_code/lounge/:lounge_code',
              element: <LoungeFinderPages.LoungePage />,
            },
          ],
        },
        {
          path: 'memberships',
          element: <MembershipsPage />,
          children: [
            {
              path: 'buy',
              element: <MembershipsPages.BuyPage />,
              children: [
                {
                  index: true,
                  element: <MembershipsBuyPages.IndexPage />,
                },
                {
                  path: 'email',
                  element: <MembershipsBuyPages.EmailPage />,
                },
                {
                  path: 'checkout',
                  element: <MembershipsBuyPages.CheckoutPage />,
                },
                {
                  path: 'processing-payment',
                  element: <MembershipsBuyPages.ProcessingPaymentPage />,
                },
                {
                  path: 'payment-succeeded',
                  element: <MembershipsBuyPages.PaymentSucceededPage />,
                },
              ],
            },
          ],
        },
        {
          path: 'trip-registration/:uuid/:token',
          element: <TripRegistrationPage />,
        },
        {
          path: 'trips/:token',
          children: [
            {
              id: 'activate',
              path: 'activate',
              element: <TripsPages.ActivatePage />,
            },
            {
              id: 'upgrade',
              path: 'upgrade/*',
              element: <TripsPages.TripsUpgradePage />,
            },
          ],
        },
        {
          path: 'upsell',
          element: <UpsellPage />,
          children: [
            {
              path: 'trips/:token',
              element: <UpsellPages.TripsPage />,
              children: [
                {
                  index: true,
                  element: <UpsellTripsPages.IndexPage />,
                },
                {
                  path: 'checkout',
                  element: <UpsellTripsPages.CheckoutPage />,
                },
                {
                  path: 'payment-succeeded',
                  element: <UpsellTripsPages.PaymentSucceededPage />,
                },
              ],
            },
          ],
        },
        // TODO: upsell route is doubled due to previous implementation and changing layout.
        //       something to clean up in the future.
        {
          path: 'upsell/trips/:token',
          children: [
            {
              path: 'processing-payment',
              element: <UpsellTripsPages.ProcessingPaymentPage />,
            },
          ],
        },
        {
          path: 'journey/:id',
          children: [
            {
              id: 'air-payout',
              path: 'air-payout/*',
              element: (
                <AirBundlePage insuranceType={InsuranceType.AIR_PAYOUT} />
              ),
            },
            {
              id: 'air-luggage',
              path: 'air-luggage/*',
              element: (
                <AirBundlePage insuranceType={InsuranceType.AIR_LUGGAGE} />
              ),
            },
          ],
        },
        {
          element: <AuthenticationLayout />,
          children: [
            {
              path: 'signup',
              element: <SignupPage />,
            },
            {
              path: 'login',
              element: <LoginPage />,
            },
            {
              path: 'reset-password',
              element: <ResetPasswordPage />,
            },
            {
              path: 'set-password',
              element: <SetPasswordPage />,
            },
          ],
        },
        {
          element: <DefaultPageRouteLayout />,
          children: [
            {
              index: true,
              element: <HomePage />,
            },
            {
              path: 'claims',
              element: <ClaimsPage />,
            },
            {
              path: 'my-account',
              element: <MyAccountPage />,
              children: [
                {
                  index: true,
                  element: <MyAccountPages.IndexPage />,
                },
                {
                  path: 'special-offer',
                  element: <MyAccountPages.SpecialOfferPage />,
                },
                { path: 'details', element: <MyAccountPages.DetailsPage /> },
                {
                  path: 'notifications',
                  element: <MyAccountPages.NotificationsPage />,
                },
                { path: 'benefits', element: <MyAccountPages.BenefitsPage /> },
                {
                  path: 'billing',
                  children: [
                    {
                      index: true,
                      element: <MyAccountPages.BillingPage />,
                    },
                    {
                      path: 'processing',
                      element: <MyAccountPages.CardProcessingPage />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'journey/:id',
              element: <JourneyPage />,
              children: [
                {
                  index: true,
                  element: <JourneyPages.IndexPage />,
                },
                { path: 'flights', element: <JourneyPages.FlightsPage /> },
                { path: 'insurance', element: <JourneyPages.InsurancePage /> },
              ],
            },
            {
              path: 'journeys',
              element: <JourneysPage />,
            },
            {
              path: 'lounges',
              element: <LoungesPage />,
              children: [
                {
                  index: true,
                  element: <LoungesPages.IndexPage />,
                },
                {
                  path: 'passes',
                  element: <LoungesPages.PassesPage />,
                },
              ],
            },
            {
              path: 'r',
              element: <RedirectPage />,
            },
          ],
        },
        {
          path: '*',
          element: <NotFoundPage />,
        },
        {
          path: 'error',
          element: <ErrorPage />,
        },
      ],
    },
  ],
  routerOptions,
);

const RouterComponent = () => {
  return <RouterProvider router={newRouter} />;
};

export default RouterComponent;
