// eslint-disable-next-line import/no-cycle -- circular dependency
import { post } from 'api/ky';
import { AUTH_API_URL } from 'api/ky/gateways';
import config from 'config/config';

const AUTH_API_VERSION = config.authApiVersion;

export const REFRESH_ACCESS_TOKEN_URL = `${AUTH_API_URL}/api/${AUTH_API_VERSION}/auth/refresh`;
const SSO_AUTH_URL = `${AUTH_API_URL}/api/${AUTH_API_VERSION}/auth`;

export enum SSO_PROVIDER {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  FACEBOOK = 'facebook',
}

export const refreshAccessToken = async () => {
  return await post(REFRESH_ACCESS_TOKEN_URL, {}, { authRetryEnabled: false });
};

export const oAuthLink = (provider: SSO_PROVIDER) => {
  const returnUrl = new URL(window.location.href);
  return `${SSO_AUTH_URL}/${provider}?return_url=${encodeURIComponent(returnUrl.toString())}`;
};
