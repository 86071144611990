import { plainToClass } from 'class-transformer';

import { userPolicyApi } from 'api';
import { type ApiQueryOptions } from 'hooks/api/queryHelper';
import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { AirBundleSubscription } from 'models/userPolicy/AirBundleSubscription';

const fetchAirPayoutBundleSubscription = async () => {
  const [error, response] = await userPolicyApi.fetchAirBundleSubscription();

  if (error) {
    throw error;
  } else {
    return response;
  }
};

const useFetchAirBundleSubscription = (options: ApiQueryOptions) => {
  return useProtectedQuery({
    queryKey: ['airPayoutBundleSubscription'],
    queryFn: () => fetchAirPayoutBundleSubscription(),
    select: (data) => plainToClass(AirBundleSubscription, data),
    ...options,
  });
};

export default useFetchAirBundleSubscription;
