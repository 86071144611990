import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';

import { Button } from '@chakra-ui/react';

import { PAGE_PATHS } from 'config/routes';
import { TranslationKey } from 'types/i18n';
import { AirLuggageJourney, AirLuggageStatus } from '@airhelp/plus';

const AirLuggageAction = ({
  airLuggageJourney,
}: {
  airLuggageJourney: AirLuggageJourney;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { status } = airLuggageJourney;

  const getPaidHandler = (update: boolean) => {
    const page = update
      ? PAGE_PATHS.JOURNEY.AIR_LUGGAGE.PAYOUT_UPDATE_PAGE
      : PAGE_PATHS.JOURNEY.AIR_LUGGAGE.PAYOUT_PAGE;
    const search =
      update && airLuggageJourney.transactionReference
        ? `?tr=${encodeURIComponent(airLuggageJourney.transactionReference)}`
        : '';

    navigate(
      generatePath(page, { id: airLuggageJourney.journeyId.toString() }) +
        search,
    );
  };

  const getAdditionalContent = (tKey: TranslationKey, update = false) => {
    const id = update
      ? 'air-luggage-update-bank-details-button'
      : 'air-luggage-get-paid-button';
    return (
      <Button
        onClick={() => getPaidHandler(update)}
        size="xs"
        backgroundColor="danger.500"
        color="greyscale.100"
        maxHeight="32px"
        _hover={{ backgroundColor: 'danger.400' }}
        data-testid={id}
      >
        {t(tKey)}
      </Button>
    );
  };

  if (status === AirLuggageStatus.ELIGIBLE) {
    return getAdditionalContent('air_luggage.apply_for_luggage');
  }
  if (
    status === AirLuggageStatus.PAYOUT_FAILED ||
    status === AirLuggageStatus.PAYOUT_RETURNED
  ) {
    return getAdditionalContent('common.update_bank_details', true);
  }
  return t('common.no_action');
};

export default AirLuggageAction;
