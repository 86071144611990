import React from 'react';
import track from 'react-tracking';

import HomeView from 'components/HomeView';
import Onboarding from 'components/HomeView/Onboarding';
import { usePageNavigationHeader } from 'contexts/LayoutContext/LayoutContext';
import { PageLoader } from 'elements';
import useFetchLoungePasses from 'hooks/api/userProfiles/useFetchLoungePasses/useFetchLoungePasses';
import useFetchMyProfile from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import useComputedClaimEnquiries from 'hooks/api/claims/useComputedClaimEnquiries';
import useProtectedPage from 'hooks/routing/useProtectedPage';

import { usePageTracking } from 'utils/tracking';
import { useJourneys } from '@airhelp/plus/react';
import EssentialUpgrade from 'components/HomeView/EssentailUpgrade/EssentialUpgrade';

const HomePage: React.FC = () => {
  usePageTracking();
  useProtectedPage();
  usePageNavigationHeader(null);

  const { isLoading: isJourneysLoading, data: journeysData } = useJourneys();
  const { isLoading: isLoungePassesLoading, data: loungePassesData } =
    useFetchLoungePasses();
  const { data: userProfile, isLoading: isUserProfileLoading } =
    useFetchMyProfile();
  const { isLoading: isClaimEnquiriesLoading, data: claimEnquiries } =
    useComputedClaimEnquiries();

  const isLoading =
    isUserProfileLoading ||
    isJourneysLoading ||
    isLoungePassesLoading ||
    isClaimEnquiriesLoading;

  const showUpgradeModal = userProfile?.policy?.membership?.isEssential;

  if (isLoading || !userProfile) {
    return <PageLoader />;
  }

  return (
    <>
      <EssentialUpgrade canLoadBanner={Boolean(showUpgradeModal)} />
      <Onboarding />
      <HomeView
        userProfile={userProfile}
        journeys={journeysData ?? []}
        loungePasses={loungePassesData ?? []}
        claimEnquiries={claimEnquiries}
      />
    </>
  );
};

export default track({ page_type: 'Home' })(HomePage);
