import React from 'react';
import { useTranslation } from 'react-i18next';

import { ID as Id, Calendar, AltArrowForward } from '@airhelp/icons';
import { Button, Flex, HStack, Text } from '@chakra-ui/react';

import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import type UserProfile from 'models/UserProfile';
import { useTrackEvent } from 'utils/tracking/hooks';

import PlanDetailsList, {
  type PlanItem,
} from '../PlanDetailsList/PlanDetailsList';
import Section from '../Section';
import { getWebsiteAhPlusPageUrl } from 'utils/sites';

const YourPlanInfo: React.FC<{
  userProfile: UserProfile;
}> = ({ userProfile }) => {
  const { t } = useTranslation();
  const { trackCtaClick } = useTrackEvent();
  const { locale } = useLocale();

  const policy = userProfile.policy;

  const hasActiveMembership = policy.membership?.active;
  const upsellEnabled = policy.dashboard?.upsellEnabled;

  const planInfo: PlanItem[] = [
    {
      title: policy.membership?.displayName || '',
      icon: Id,
      key: 'airhelp_plus_plan',
    },
    {
      title_tkey: `my_account.my_benefits.your_plan.coverage_period${
        policy.membership?.autoRenewal ? '_auto_renewal' : ''
      }`,
      value: {
        startingDate: policy.membership?.startDate || '',
        endingDate: policy.membership?.endDate || '',
      },
      icon: Calendar,
      key: 'auto_renewal',
    },
  ];

  return (
    <Section
      header={t('common.your_plan')}
      {...(!hasActiveMembership && { alignItems: 'center' })}
    >
      {hasActiveMembership ? (
        <PlanDetailsList planDetails={planInfo} />
      ) : (
        <Flex
          w={{ base: '100%', md: '67%' }}
          alignContent="center"
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}
          rowGap={4}
        >
          <HStack>
            <Id
              boxSize={9}
              p={2}
              bg="primary.100"
              color="primary.500"
              borderRadius="md"
              mr={3}
            />
            <Text color="primary.700" fontSize="md">
              {t('my_account.my_benefits.your_plan.currently_no_plan')}
            </Text>
          </HStack>
          {upsellEnabled ? (
            <Button
              width={{ base: '100%', md: 'auto' }}
              as="a"
              href={getWebsiteAhPlusPageUrl('pricing-plans', locale)}
              target="_blank"
              rel="noopener noreferrer"
              size="s"
              onClick={() => {
                trackCtaClick('check our plans', 'my benefits page');
              }}
            >
              {t('common.check_our_plans')}
              <AltArrowForward ml={2} />
            </Button>
          ) : null}
        </Flex>
      )}
    </Section>
  );
};

export default YourPlanInfo;
