import { plainToClass } from 'class-transformer';
import { type Consents as IConsents } from '@airhelp/plus';

export class Consents implements IConsents {
  marketingConsent: boolean;
  whatsappConsent: boolean;
  flirhtNotificationsConsent: boolean;
}

export function transformToConsentsClass(consents: IConsents): Consents {
  return plainToClass(Consents, consents);
}
