import React from 'react';
import { useTranslation } from 'react-i18next';

import perkLogo from 'assets/images/perks/logo-airalo.svg';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useTrackEvent } from 'utils/tracking/hooks';

import { getAiraloLinkByLocale } from './airaloLinkHelper';
import PerkDrawerContent from '../PerkDrawerContent';

interface IComponent {
  code: string;
  discount: number;
  imageText: string;
  imageSrcMobile: string;
}

const TRACKING_PROVIDER_NAME = 'airalo';

const ESim: React.FC<IComponent> = ({
  code,
  discount,
  imageText,
  imageSrcMobile,
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { trackPageInteractions } = useTrackEvent();

  const siteUrl = getAiraloLinkByLocale(locale);

  const benefits = [
    {
      content: t('dashboard.perks.esim.instant_connectivity'),
      key: 'instant_connectivity',
    },
    {
      content: t('dashboard.perks.esim.global_coverage'),
      key: 'global_coverage',
    },
    {
      content: t('dashboard.perks.esim.affordable_and_transparent'),
      key: 'affordable_and_transparent',
    },
    { content: t('dashboard.perks.esim.support'), key: 'support' },
  ];

  const goToSite = () => {
    trackPageInteractions(
      'go to site',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );

    window.open(siteUrl, '_blank');
  };

  const onCodeCopied = () => {
    trackPageInteractions(
      'promo code copy',
      TRACKING_PROVIDER_NAME,
      'travel service banner',
    );
  };

  return (
    <PerkDrawerContent
      heading={imageText}
      imageSrcMobile={imageSrcMobile}
      perkLogo={perkLogo}
      discount={discount}
      subHeading="dashboard.perks.esim.percentage_off_your_esim"
      descriptionPrimary={t(
        'dashboard.perks.esim.stay_connected_when_your_travel',
        {
          discount,
        },
      )}
      descriptionSecondary={t(
        'dashboard.perks.esim.even_found_desperately_searching',
      )}
      benefits={benefits}
      code={code}
      onCodeCopied={onCodeCopied}
      promoCodeText={t('dashboard.perks.use_it_on', { url: 'airalo.com' })}
      goToSite={goToSite}
      buttonLabel={t('dashboard.perks.esim.get_esim_card')}
    />
  );
};

export default ESim;
