export type Currency = 'EUR' | 'USD';

export enum STRIPE_PAYMENT_STATUSES {
  SUCCEEDED = 'succeeded',
  REQUIRES_PAYMENT_METHOD = 'requires_payment_method',
}

export enum STRIPE_TEST_PAYMENT_STATUSES {
  TEST = 'test-payment-status',
}
