import { type ApiQueryOptions } from 'hooks/api/queryHelper';

import { useProtectedQuery } from 'hooks/api/useProtectedQuery';
import { transformToConsentsClass } from 'models/userProfile/Consents';
import { fetchConsents, Consents } from '@airhelp/plus';
import { ahplusClient } from 'api/clients/clients';

interface FetchConsentsResponse {
  consents: Consents;
}

const useFetchConsents = (options?: ApiQueryOptions<FetchConsentsResponse>) => {
  return useProtectedQuery({
    queryKey: ['userProfile', 'consents'],
    queryFn: () => fetchConsents({ client: ahplusClient }),
    select: ({ consents }) => transformToConsentsClass(consents),
    ...options,
  });
};

export default useFetchConsents;
