import React from 'react';
import { useTranslation } from 'react-i18next';

import { Check, Info } from '@airhelp/icons';
import { ProductName } from '@airhelp/plus';
import {
  Box,
  Flex,
  HStack,
  List,
  ListItem,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';

import AirhelpBrandWithPlanName from 'elements/AirhelpBrandWithPlanName';
import { type Offer } from 'models/Offers';
import { usePreferencesStore } from 'stores/preferences';
import {
  formatValueToCurrency,
  getLocalPriceValue,
} from 'stores/preferences/currencies';

interface TripToCompleteDetailsViewProps {
  offers: Record<string, Offer>;
}

const TripToCompleteDetailsView: React.FC<TripToCompleteDetailsViewProps> = ({
  offers,
}) => {
  const { t } = useTranslation();
  const currency = usePreferencesStore((state) => state.currency);

  const stepItems = [
    { text: t('trips.upgrade.annual.benefits.expenses_claims') },
    { text: t('trips.upgrade.annual.benefits.luggage_claims') },
    { text: t('trips.upgrade.annual.benefits.compensation') },
    { text: t('trips.upgrade.annual.benefits.assistance_with_refunds') },
    { text: t('trips.upgrade.annual.benefits.chat_support') },
    {
      text: t('trips.upgrade.annual.benefits.airport_lounge_access'),
      weight: 'bold',
      tip: t('pricing.benefits.airport_lounge_access_tip'),
    },
    {
      text: t('trips.upgrade.annual.benefits.range_of_lounge_amenities'),
      weight: 'bold',
      tip: t('pricing.benefits.range_of_lounge_amenities_included_tip'),
    },
    {
      text: t('insurance.upsell.benefits.single_trip_coverage'),
      weight: 'bold',
    },
  ];

  const product = offers[ProductName.UPGRADE_TO_TRIP_COMPLETE];

  const localPrice = getLocalPriceValue(product?.prices, currency);

  return (
    <Flex
      width={{ base: '100%', lg: '440px' }}
      flexDirection="column"
      flex={{ base: 'none', lg: 'none' }}
    >
      <AirhelpBrandWithPlanName
        label={t('flights_list.your_upcoming_trip')}
        description={t('complete_upsell.get_complete')}
      />{' '}
      <Box>
        <Text fontSize="lg" fontWeight="medium" mt={12} mb={2}>
          {t('memberships.your_benefits')}
        </Text>
        <Text color="greyscale.700" fontSize="md">
          {t('insurance.upsell.you_upgrade_upcoming_trip', {
            previousPlan: t('pricing.plans.essential'),
            newPlan: t('pricing.plans.complete'),
          })}
        </Text>
        <List spacing={4} fontSize="md" mt={8} mb={6}>
          {stepItems.map((benefit) => (
            <ListItem
              key={benefit.text}
              display="flex"
              alignItems="flex-start"
              color="greyscale.700"
            >
              <Check boxSize={5} mr={2} color="primary.500" />
              <Box>
                {benefit.text}
                {benefit.tip ? (
                  <Tooltip
                    label={benefit.tip}
                    hasArrow
                    arrowPadding={6}
                    bgColor="greyscale.800"
                    borderRadius="md"
                    p={4}
                  >
                    <Info
                      color="greyscale.700"
                      ml={1}
                      boxSize={4}
                      h="auto"
                      verticalAlign="text-top"
                      cursor="pointer"
                    />
                  </Tooltip>
                ) : null}
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
      <VStack spacing={4} mt={7}>
        <HStack width="100%" justifyContent="space-between">
          <Text fontSize="lg" color="greyscale.500">
            {`${t('common.total')}:`}
          </Text>
          <Text fontSize="lg" fontWeight="medium">
            {formatValueToCurrency(localPrice, currency)}
          </Text>
        </HStack>
      </VStack>
    </Flex>
  );
};

export default TripToCompleteDetailsView;
