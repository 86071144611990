import React, { type PropsWithChildren } from 'react';
import { useLocation, matchPath } from 'react-router-dom';

import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';

import appTheme from 'components/ChakraProvider/theme';
import { ALL_AVAILABLE_ROUTES, PAGE_PATHS } from 'config/routes';
import { useLayout } from 'contexts/LayoutContext/LayoutContext';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';

import Header from './Header';
import MobileHeader from './MobileHeader';
import NavigationBar from './NavigationBar';
import NotificationHeader from './NotificationHeader';

const ROUTES_WITHOUT_NAVBAR = [PAGE_PATHS.SET_PASSWORD_PAGE];
const ROUTES_WITH_HOME_SCREEN_BANNER = [PAGE_PATHS.HOME_PAGE];

const Default: React.FC<PropsWithChildren> = ({ children }) => {
  const { userProfile } = useMyProfile();
  const { displayAddToHomeScreenBanner } = useLayout();
  const { mobileHeaderConfig } = useLayout();
  const { pathname } = useLocation();

  const shouldHideNavbar = (path: string) => {
    const hasMatchPath = Boolean(
      ALL_AVAILABLE_ROUTES.find((route) => matchPath(route, pathname)),
    );
    return ROUTES_WITHOUT_NAVBAR.includes(path) || !hasMatchPath;
  };

  const showNavbar = Boolean(userProfile) && !shouldHideNavbar(pathname);

  const showTopBannerEssentialUpgrade = Boolean(
    userProfile?.policy?.membership?.isEssential,
  );

  const showAddToHomeScreenBanner =
    displayAddToHomeScreenBanner &&
    !showTopBannerEssentialUpgrade &&
    ROUTES_WITH_HOME_SCREEN_BANNER.includes(pathname);

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const shouldDisplayMobileHeader =
    isMobile && Boolean(mobileHeaderConfig?.title);

  if (showNavbar) {
    return (
      <>
        {showAddToHomeScreenBanner ? (
          <NotificationHeader />
        ) : (
          <Box
            position={{ base: 'fixed', lg: 'relative' }}
            top="0"
            left="0"
            right="0"
            zIndex="100"
          >
            {shouldDisplayMobileHeader ? <MobileHeader /> : <Header />}
          </Box>
        )}
        <Box
          pt={{
            base:
              showAddToHomeScreenBanner || showTopBannerEssentialUpgrade
                ? '120px'
                : 16,
            lg: '140px',
          }}
          pb={20}
          pl={{ base: 0, lg: '278px' }}
        >
          <Flex
            flexDirection={{ base: 'column', lg: 'initial' }}
            justifyContent="center"
            px={{ base: 4, lg: 8 }}
          >
            <Flex
              justifyContent="center"
              width="100%"
              maxW={{ base: '100%', lg: appTheme.breakpoints.xlg }}
              minHeight="100vh"
              mt={{ base: 4, lg: 0 }}
            >
              {children}
            </Flex>
          </Flex>
        </Box>
        <NavigationBar userProfilePolicy={userProfile?.policy} />
      </>
    );
  }

  return (
    <Flex alignItems="center" justifyContent="center" height="100%">
      {children}
    </Flex>
  );
};

export default Default;
