import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { AltArrowForward } from '@airhelp/icons';
import { Button, Flex, HStack, Image, Text } from '@chakra-ui/react';

import noActiveLougePassesImage from 'assets/images/lounges/hassle-free-buka@2x.png';
import spotLoungeImage from 'assets/images/lounges/spot-lounges-buka@2x.png';
import { Card } from 'elements';
import { type LoungePass } from 'models/LoungePass';

import NoLoungePassesTooltip from '../NoLoungePassesTooltip';
import { MIN_CARD_WIDTH } from 'config/lounge';

interface IComponent {
  loungePasses: LoungePass[];
  handleClick: () => void;
}

const LoungeExperienceCard: React.FC<IComponent> = ({
  loungePasses,
  handleClick,
}) => {
  const { t } = useTranslation();

  const loungesCount = loungePasses.length;

  const hasLoungePasses = loungesCount > 0;

  return (
    <Card
      py={10}
      px={{ base: 5, lg: 8 }}
      data-testid="lounge-experience-card"
      flex={{ base: 'initial', lg: '1' }}
      minWidth={{ base: '100%', lg: MIN_CARD_WIDTH }}
    >
      <Flex
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        h="100%"
      >
        {hasLoungePasses ? (
          <Text fontSize="lg" fontWeight="bold">
            <Trans
              i18nKey="lounges.your_available_lounge_passes"
              values={{ count: loungesCount }}
              components={{
                strong: (
                  <Text
                    as="span"
                    fontWeight="bold"
                    fontSize="lg"
                    color="danger.500"
                    display="inline"
                  />
                ),
              }}
            />
          </Text>
        ) : (
          <Text fontWeight="bold" fontSize="lg" textAlign="center">
            {t('lounges.lounge_experience')}
          </Text>
        )}
        <HStack flexShrink={0}>
          <Image
            src={hasLoungePasses ? spotLoungeImage : noActiveLougePassesImage}
            alt=""
            height="265px"
            position="relative"
            my={3}
            py={hasLoungePasses ? 0 : 6}
          />
        </HStack>
        {hasLoungePasses ? (
          <Button
            variant="primaryAlt"
            size="s"
            onClick={handleClick}
            display="inherit"
            rightIcon={<AltArrowForward boxSize={5} />}
            data-testid="view-passes-button"
          >
            <Text fontSize="inherit" color="inherit">
              {t('lounges.view_passes')}
            </Text>
          </Button>
        ) : null}
        <Text
          fontSize={hasLoungePasses ? 'sm' : 'md'}
          fontWeight={hasLoungePasses ? 'normal' : 'medium'}
          mt={5}
          textAlign="center"
          color={hasLoungePasses ? 'greyscale.600' : 'primary.900'}
          display="inline"
        >
          {t(
            hasLoungePasses
              ? 'lounges.3_months_to_use'
              : 'lounges.no_flight_disruption',
          )}
          {!hasLoungePasses && <NoLoungePassesTooltip />}
        </Text>
      </Flex>
    </Card>
  );
};

export default LoungeExperienceCard;
