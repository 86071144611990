import React, { useEffect, useState } from 'react';

import { paymentsApi } from 'api';
import { type HttpError } from 'types/api';

import { STRIPE_PAYMENT_STATUSES } from 'types/payments';
import ProcessingComponent from './ProcessingComponent';
import { generatePath, Navigate } from 'react-router-dom';
import { PAGE_PATHS } from 'config/routes';
import { useTranslation } from 'react-i18next';

interface PaymentStatusResponse {
  setupIntentId: string;
  status: STRIPE_PAYMENT_STATUSES;
  errorCode: string | null;
}

const CHECKING_TIME_STAGE_1 = 2; // 2s
const CHECKING_TIME_STAGE_2 = 10; // 10s

type GetPaymentStatusType = [HttpError | null, PaymentStatusResponse];

const CardProcessingView = ({
  setupIntentId,
  onPaymentFailed,
}: {
  setupIntentId: string;
  onPaymentFailed?: () => void;
}) => {
  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(0);
  const [isStatusChecking, setIsStatusChecking] = useState(true);
  const [checkingStatusFrequency, setCheckingStatusFrequency] =
    useState<number>(CHECKING_TIME_STAGE_1);
  const [paymentStatus, setPaymentStatus] = useState<STRIPE_PAYMENT_STATUSES>(
    STRIPE_PAYMENT_STATUSES.REQUIRES_PAYMENT_METHOD,
  );
  const [stripePaymentError, setStripePaymentError] = useState(false);

  const processPayment = async () => {
    const [paymentStatusError, paymentStatusData] =
      (await paymentsApi.getStripePaymentStatus(
        setupIntentId,
      )) as GetPaymentStatusType;
    const { status, errorCode } = paymentStatusData;

    if (status === STRIPE_PAYMENT_STATUSES.SUCCEEDED) {
      setIsStatusChecking(false);
      setPaymentStatus(STRIPE_PAYMENT_STATUSES.SUCCEEDED);
    }
    if (paymentStatusError || errorCode) {
      if (onPaymentFailed) {
        onPaymentFailed();
      }
      setIsStatusChecking(false);
      setStripePaymentError(true);
    }
  };

  useEffect(() => {
    if (isStatusChecking) {
      const intervalId = setInterval(() => {
        setSeconds((seconds) => seconds + 1);

        if (seconds > 0 && seconds % checkingStatusFrequency === 0) {
          processPayment();
        }

        if (seconds === CHECKING_TIME_STAGE_2) {
          setCheckingStatusFrequency(CHECKING_TIME_STAGE_2);
        }

        if (seconds > 60) {
          setIsStatusChecking(false);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isStatusChecking, checkingStatusFrequency, seconds]);

  if (seconds > 60) {
    return (
      <ProcessingComponent
        title={t('payment_management.processing.still_processing')}
        description={t(
          'payment_management.processing.still_processing_description',
        )}
      />
    );
  }
  if (stripePaymentError) {
    return (
      <ProcessingComponent
        title={t('errors.something_went_wrong')}
        description={t('payment_management.processing.error_description')}
        isError
      />
    );
  }

  switch (paymentStatus) {
    case STRIPE_PAYMENT_STATUSES.SUCCEEDED:
      return (
        <Navigate
          to={{
            pathname: generatePath(PAGE_PATHS.MY_ACCOUNT.BILLING.BILLING_PAGE),
          }}
          replace
        />
      );

    default:
      return (
        <ProcessingComponent
          title={t('payment_management.processing.processed')}
          description={t('payment_management.processing.processed_description')}
          isProcessing
        />
      );
  }
};

export default CardProcessingView;
