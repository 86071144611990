import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { Banner } from '@airhelp/react';

import {
  BANNER_CHANNEL,
  BANNER_CHANNEL_PRO,
  ESSENTIAL_TO_SMART_UPGRADE,
} from 'config/channels';
import { PAGE_PATHS } from 'config/routes';
import { useMyProfile } from 'hooks/api/userProfiles/useFetchMyProfile/useFetchMyProfile';
import { type Offer } from 'models/Offers';
import { ProductDisplayName, ProductName } from '@airhelp/plus';

import {
  isProAnnualMembership,
  isSmartAnnualMembership,
  isLiteAnnualMembership,
} from '../membershipHelper';

const MembershipCheckoutBanner = ({
  productDetails,
}: {
  productDetails: Offer;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userProfile } = useMyProfile();
  const policy = userProfile?.policy;
  const [searchParams] = useSearchParams();

  if (!policy?.membership || !productDetails) {
    return;
  }

  const isProProduct = isProAnnualMembership(productDetails.name);
  const isSmartProduct = isSmartAnnualMembership(productDetails.name);
  const isLiteProduct = isLiteAnnualMembership(productDetails.name);

  const isMembershipComplete = policy.membership.isComplete;
  const isMembershipEssential = policy.membership.isEssential;

  const currentProductName = policy.membership.productName;
  const currentProductDisplayName = policy.membership?.displayName;

  const isMembershipSmart =
    currentProductName === ProductName.AIRHELP_SMART_ANNUALLY;
  const isMembershipPro =
    currentProductName === ProductName.AIRHELP_PRO_ANNUALLY;

  const getBannerLabel = () => {
    if (
      isMembershipComplete &&
      searchParams.get('channel') !== ESSENTIAL_TO_SMART_UPGRADE
    ) {
      return t('memberships.no_need_to_purchase_this_subscription', {
        plan: t('pricing.plans.complete'),
      });
    }

    if (isMembershipPro) {
      return t('memberships.no_need_to_purchase_this_subscription_plan', {
        plan: currentProductDisplayName,
      });
    }

    if (isMembershipSmart && isSmartProduct) {
      return t('memberships.upgrade_to_complete_plan', {
        plan: currentProductDisplayName,
        newPlan: ProductDisplayName.AIRHELP_PLUS_PRO,
      });
    }
  };

  const getButtonLabel = () => {
    if (isMembershipComplete || isMembershipPro) {
      return t('common.continue_to_just_dashboard');
    }

    return t('common.upgrade');
  };

  const onButtonAction = () => {
    if (isMembershipComplete || isMembershipPro) {
      return navigate(generatePath(PAGE_PATHS.HOME_PAGE));
    }

    if (isMembershipSmart && isSmartProduct) {
      searchParams.set('tier', 'pro');
      searchParams.set('channel', BANNER_CHANNEL_PRO);
    } else {
      searchParams.set('channel', BANNER_CHANNEL);
    }

    searchParams.delete('renew');

    // TODO - remove this when we have a proper solution for not allowing 2 memberships
    navigate({
      pathname: generatePath(PAGE_PATHS.MEMBERSHIPS.BUY.CHECKOUT_PAGE),
      search: searchParams.toString(),
    });
    return navigate(0);
  };

  if (
    (isMembershipEssential && (isSmartProduct || isProProduct)) ||
    (isMembershipSmart && isProProduct) ||
    !getBannerLabel() ||
    isLiteProduct
  ) {
    return;
  }

  return (
    <Banner
      variant="warning"
      title={getBannerLabel()}
      width="100%"
      buttonLabel={getButtonLabel()}
      buttonAction={onButtonAction}
    />
  );
};

export default MembershipCheckoutBanner;
