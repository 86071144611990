import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { useDisclosure } from '@chakra-ui/react';

import config from 'config/config';
import { EditedJourneyContext } from 'contexts';
import { EditableTypes } from 'reducers/editableJourney';

import PassengerForm from '../PassengerForm';
import { EditableFellowPassenger } from '@airhelp/plus';

const AddFellowPassenger = () => {
  const { dispatch, editedItinerary, itineraryIndex } =
    useContext(EditedJourneyContext);
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { fellowPassengers } = editedItinerary;

  const generateTempId = () => Math.floor(Math.random() * Date.now());

  const form = useForm<EditableFellowPassenger>({ mode: 'onChange' });
  const allowedFellowPassengersCountReached =
    fellowPassengers.length >= config.maxFellowPassengers;

  const onSubmit = (data: Omit<EditableFellowPassenger, 'id'>) => {
    dispatch({
      type: EditableTypes.ADD_FELLOW_PASSENGER,
      itineraryIndex,
      payload: {
        id: generateTempId(),
        ...data,
      },
    });
    form.reset({ firstName: '', lastName: '' });
    onClose();
  };

  const handleCancel = () => {
    form.reset({ firstName: '', lastName: '' });
    onClose();
  };

  const handleAdd = () => {
    onToggle();
  };

  return (
    <PassengerForm
      form={form}
      isOpen={isOpen}
      handleAdd={handleAdd}
      disabledAdd={allowedFellowPassengersCountReached}
      handleCancel={handleCancel}
      onSubmit={onSubmit}
    />
  );
};

export default AddFellowPassenger;
