import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';
import { HTTPError, type KyInstance, TimeoutError, default as ky } from 'ky';

import { type HttpError, type HttpResponse } from 'types/api';

import { ahplusGateway } from './gateways';
// eslint-disable-next-line import/no-cycle
import { refreshAccessToken } from 'api/auth/auth';

export const isHttpError = (subject) => {
  return subject instanceof HTTPError || subject instanceof TimeoutError;
};

export const notAuthorized = (error: unknown) => {
  return error instanceof HTTPError && error?.response?.status === 401;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const normalizeData = (data: any): any => {
  return camelcaseKeys<any>(data, { deep: true });
};

export const handleErrorBody = async (error: HTTPError) => {
  try {
    const errorBody = await error?.response?.json();

    return normalizeData(errorBody);
  } catch {
    return null;
  }
};

export const transformResponse = async (fn, ...args): Promise<HttpResponse> => {
  try {
    return [null, await fn(...args)];
  } catch (error) {
    if (isHttpError(error)) {
      return [error as HttpError];
    }
    throw error;
  }
};

export const authRetryOptions = {
  retry: {
    limit: 1,
    statusCodes: [401],
    methods: ['get', 'post', 'put', 'patch', 'delete'],
  },
  hooks: {
    beforeRetry: [
      async () => {
        await refreshAccessToken();
      },
    ],
  },
};

interface RequestParams {
  options: any;
  authRetryEnabled: boolean;
  snakecaseKeysOption: boolean;
  kyInstance: KyInstance;
}

const defaultParams: RequestParams = Object.freeze({
  options: {},
  authRetryEnabled: true,
  snakecaseKeysOption: true,
  kyInstance: ahplusGateway,
});

const authRetry = (authRetryEnabled: boolean) =>
  authRetryEnabled ? authRetryOptions : {};

export const kyGet = (url: string, params: RequestParams) => {
  const { authRetryEnabled, kyInstance, options } = {
    ...defaultParams,
    ...params,
  };

  return kyInstance.get(url, {
    ...authRetry(authRetryEnabled),
    ...options,
  });
};

export const get = async (url: string, params?: Partial<RequestParams>) => {
  return await transformResponse(async () => {
    const response = await kyGet(url, { ...defaultParams, ...params }).json();

    return normalizeData(response);
  });
};

export const kyPost = (url: string, body: any, params: RequestParams) => {
  const { authRetryEnabled, snakecaseKeysOption, kyInstance, options } = {
    ...defaultParams,
    ...params,
  };

  return kyInstance.post(url, {
    json: snakecaseKeysOption ? snakecaseKeys(body, { deep: true }) : body,
    ...authRetry(authRetryEnabled),
    ...options,
  });
};

export const post = async (
  url: string,
  body: any,
  params?: Partial<RequestParams>,
) => {
  return await transformResponse(async () => {
    const response = await kyPost(url, body, {
      ...defaultParams,
      ...params,
    }).json();

    return normalizeData(response);
  });
};

export const kyPut = (url: string, body: any, params: RequestParams) => {
  const { authRetryEnabled, snakecaseKeysOption, kyInstance, options } = {
    ...defaultParams,
    ...params,
  };

  return kyInstance.put(url, {
    json: snakecaseKeysOption ? snakecaseKeys(body, { deep: true }) : body,
    ...authRetry(authRetryEnabled),
    ...options,
  });
};

export const put = async (
  url: string,
  body: any,
  params?: Partial<RequestParams>,
) => {
  return await transformResponse(async () => {
    const response = await kyPut(url, body, {
      ...defaultParams,
      ...params,
    }).json();

    return normalizeData(response);
  });
};

export const kyPatch = (url: string, body: any, params: RequestParams) => {
  const { authRetryEnabled, snakecaseKeysOption, kyInstance, options } = {
    ...defaultParams,
    ...params,
  };

  return kyInstance.patch(url, {
    json: snakecaseKeysOption ? snakecaseKeys(body, { deep: true }) : body,
    ...authRetry(authRetryEnabled),
    ...options,
  });
};

export const patch = async (
  url: string,
  body: any,
  params?: Partial<RequestParams>,
) => {
  return await transformResponse(async () => {
    const response = await kyPatch(url, body, {
      ...defaultParams,
      ...params,
    }).json();

    return normalizeData(response);
  });
};

/* eslint-enable @typescript-eslint/no-explicit-any */
export const upload = async (url: string, file: File) => {
  return ky.put(url, { body: file, timeout: 600000 });
};

export const kyDelete = (url: string, params: RequestParams) => {
  const { authRetryEnabled, kyInstance, options } = {
    ...defaultParams,
    ...params,
  };

  return kyInstance.delete(url, {
    ...authRetry(authRetryEnabled),
    ...options,
  });
};

export const del = async (url: string, params?: Partial<RequestParams>) => {
  return await transformResponse(async () => {
    const response = await kyDelete(url, {
      ...defaultParams,
      ...params,
    }).json();

    return normalizeData(response);
  });
};
