import React from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  Flex,
  Heading,
  Text,
  VStack,
  useBreakpointValue,
} from '@chakra-ui/react';

import { PAGE_PATHS } from 'config/routes';
import { type LoungePass } from 'models/LoungePass';
import { useTrackEvent } from 'utils/tracking/hooks';

import FAQs from './FAQs';
import HowItWorks from './HowItWorks';
import LoungeExperienceBanner from './LoungeExperienceBanner';
import LoungeExperienceCard from './LoungeExperienceCard';
import SearchLoungesImage from './SearchLoungesImage';
import LoungesOffer from './LoungesOffer';

interface IComponent {
  loungePasses: LoungePass[];
}

const LoungesView: React.FC<IComponent> = ({ loungePasses = [] }) => {
  const { t } = useTranslation();
  const { trackCtaClick, trackPageInteractions } = useTrackEvent();
  const navigate = useNavigate();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleLoungeExperienceCardClick = (): void => {
    trackCtaClick('your available lounge passes', 'lounges page');

    navigate(generatePath(PAGE_PATHS.LOUNGES.PASSES_PAGE));
  };

  const onLoungesOfferCollapsibleToggle = (isToggle: boolean) => {
    isToggle && trackPageInteractions('clicked', 'what most lounges offer');
  };

  const onFAQCollapsibleToggle = (isToggle: boolean) => {
    isToggle && trackPageInteractions('clicked', 'FAQ item');
  };

  const handleSearchLoungesImageClick = () => {
    navigate(generatePath(PAGE_PATHS.LOUNGE_FINDER_PAGE));

    trackCtaClick('lounge finder', 'lounges page');
  };

  const onHowItWorksCollapsibleToggle = (isToggle: boolean) => {
    isToggle && trackPageInteractions('clicked', 'how it works');
  };

  const LoungeExperienceComponent = isMobile
    ? LoungeExperienceBanner
    : LoungeExperienceCard;

  return (
    <VStack width="100%" alignItems="stretch" spacing={8}>
      <VStack
        maxW={{ base: '100%', md: '527px' }}
        spacing={4}
        mb={2}
        alignItems="flex-start"
      >
        <Heading variant="h4" mt={{ base: 6, md: 4, lg: 0 }}>
          {t('lounges.worldwide')}
        </Heading>
        <Text fontSize="md">{t('lounges.spend_your_flight')}</Text>
      </VStack>

      <Flex
        flexDirection={{ base: 'column', xlg: 'row' }}
        columnGap={{ base: 5, md: 8 }}
        rowGap={{ base: 5, md: 8 }}
        flexWrap="wrap"
        width="100%"
      >
        <Flex
          rowGap={{ base: 5, md: 8 }}
          columnGap={8}
          flexDirection={{ base: 'column', lg: 'row' }}
          width="100%"
          flexWrap="wrap"
        >
          <LoungeExperienceComponent
            loungePasses={loungePasses}
            handleClick={handleLoungeExperienceCardClick}
          />
          <HowItWorks onToggle={onHowItWorksCollapsibleToggle} />
          <LoungesOffer onToggle={onLoungesOfferCollapsibleToggle} />
        </Flex>
        <Flex
          flexDirection={{ base: 'column-reverse', xlg: 'row' }}
          flexWrap="wrap"
          gap={8}
          width="100%"
        >
          <SearchLoungesImage
            handleClick={handleSearchLoungesImageClick}
            width={{ base: '100%', xlg: 'calc(67% - 16px)' }}
          />
        </Flex>
        <FAQs
          onToggle={onFAQCollapsibleToggle}
          width={{ base: '100%', xlg: 'calc(67% - 16px)' }}
        />
      </Flex>
    </VStack>
  );
};

export default LoungesView;
