import { HStack, Text } from '@chakra-ui/react';

const LabelValue = ({ label, value }: { label: string; value: string }) => {
  return (
    <HStack
      spacing={0}
      justifyContent="space-between"
      width="100%"
      alignItems="flex-start"
    >
      <Text fontSize="sm" color="greyscale.600">
        {label}
      </Text>
      <Text fontSize="md" textAlign="right">
        {value}
      </Text>
    </HStack>
  );
};

export default LabelValue;
