import {
  isLiteAnnualMembership,
  isSmartAnnualMembership,
  isProAnnualMembership,
} from 'components/Membership/membershipHelper';
import { type Order } from 'models/Order';
import { ProductName } from '@airhelp/plus';

const getVariant = (product: ProductName): string => {
  if (isSmartAnnualMembership(product)) {
    return 'smart';
  } else if (isLiteAnnualMembership(product)) {
    return 'lite';
  } else if (isProAnnualMembership(product)) {
    return 'pro';
  }
  return 'essential';
};

export const stripeMembershipEcommerce = ({
  order,
  brand,
  product,
}: {
  order: Order;
  brand: string | undefined;
  product: ProductName;
}) => {
  return {
    ecommerce: {
      purchase: {
        actionField: {
          action: 'purchase',
          id: order.identifier,
          revenue: 39.0, // pre-calculated marketing client life time value
        },
        products: [
          {
            brand: brand || 'AirHelp',
            category: '1YearMembership',
            id: product,
            name: 'AirHelpPlus',
            price: Number.parseFloat(order.totalAmount),
            quantity: 1,
            variant: getVariant(product),
          },
        ],
      },
    },
  };
};

export const stripeInsuranceEcommerce = ({ order }: { order: Order }) => {
  return {
    ecommerce: {
      purchase: {
        actionField: {
          action: 'purchase',
          id: order.identifier,
          revenue: Number.parseFloat(order.totalAmount),
        },
        products: [
          {
            brand: 'AirHelp',
            name: 'AirHelpPlus',
            id: 'air_payout',
            channel: 'trip_activation',
            quantity: 1,
            price: Number.parseFloat(order.totalAmount),
          },
        ],
      },
    },
  };
};
