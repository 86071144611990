import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const MotionBox = motion(Box);

const StripePaymentHeader = ({
  showAddressForm,
  isUpdate,
  completeForm,
}: {
  showAddressForm?: boolean;
  isUpdate?: boolean;
  completeForm: boolean;
}) => {
  const { t } = useTranslation();

  const getStep = showAddressForm ? 2 : 1;

  const getHeader = () => {
    if (showAddressForm) {
      if (isUpdate) {
        return t('payment_management.update_billing_address');
      }
      return t('payment_management.add_billing_address');
    }
    return t('payment_management.choose_payment_method');
  };

  const getProgressBarWidth = () =>
    completeForm ? '100%' : showAddressForm ? '50%' : '15%';

  const getProgressBarColor = () => {
    const colorStops = completeForm
      ? '50%, #8C62FC 50%'
      : showAddressForm
        ? '50%, #8C62FC 100%'
        : '100%';
    return `linear-gradient(89deg, #0D7CFF ${colorStops}, #FF6B73 100%)`;
  };

  return (
    <Box mb={{ base: 4, md: 8 }} px={{ base: -4, md: -8 }}>
      <Text fontSize="sm" color="greyscale.600" textAlign="center">
        {t('common.step')} {getStep} {t('common.of')} 2
      </Text>
      <Text fontWeight="500" fontSize="md" textAlign="center">
        {getHeader()}
      </Text>
      <Box
        ml="-32px"
        width={{ base: 'calc(100% + 40px)', md: 'calc(100% + 56px)' }}
        my={4}
      >
        <MotionBox
          height={1}
          width={getProgressBarWidth()}
          bg={getProgressBarColor()}
          animate={{
            width: getProgressBarWidth(),
            background: getProgressBarColor(),
          }}
          transition={{ duration: 0.5 }}
        />
      </Box>
    </Box>
  );
};

export default StripePaymentHeader;
