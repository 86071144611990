import { AirPayoutJourney, AirPayoutStatus } from '@airhelp/plus';
import { useTranslation } from 'react-i18next';

export const getTooltipStatus = (airPayoutJourney: AirPayoutJourney) => {
  const { t } = useTranslation();

  const { status, computed, payoutAmount } = airPayoutJourney;
  const { outboundOnly, cancelationOnly } = computed;
  const airPayoutPayout = payoutAmount.toString();

  const getActiveStatusDescription = () => {
    if (outboundOnly && cancelationOnly) {
      return t('air_payout.card.description.cancelation');
    }
    return t('air_payout.card.description.added');
  };

  switch (status) {
    case AirPayoutStatus.ACTIVE:
      return getActiveStatusDescription();

    case AirPayoutStatus.ELIGIBLE:
      return t('air_payout.card.description.eligible', {
        amount: airPayoutPayout,
      });
    case AirPayoutStatus.INELIGIBLE:
    case AirPayoutStatus.MEMBERSHIP_NOT_RENEWED:
    case AirPayoutStatus.BLOCKED:
    case AirPayoutStatus.BLOCKED_UNREGISTERED:
    case AirPayoutStatus.UNREGISTERED:
      return t('air_payout.card.description.not_eligible');
    case AirPayoutStatus.REJECTED:
      return t('air_luggage.card.description.claim_rejected');
    case AirPayoutStatus.PROCESSING_PAYMENT:
    case AirPayoutStatus.MANUAL_VERIFICATION_PENDING:
      return t('air_payout.card.description.processing');
    case AirPayoutStatus.PAYOUT_FAILED:
    case AirPayoutStatus.PAYOUT_RETURNED:
      return t('air_payout.card.description.payment_failed');
    case AirPayoutStatus.PAYOUT_SUCCESSFUL:
      return t('air_payout.card.description.paid');
    case AirPayoutStatus.EXPIRED:
      return t('air_payout.card.description.expired', { value: 28 });
    default:
      return t('air_payout.card.description.default', {
        amount: airPayoutPayout,
      });
  }
};
