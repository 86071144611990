import { Expose, plainToClass, Type } from 'class-transformer';
import { getWebappClaimUrl, getWebappUrl } from 'utils/sites';

export interface IClaimEnquiryFlight {
  id: number;
  departureAirportCode: string;
  departureCity: string;
  arrivalAirportCode: string;
  arrivalCity: string;
  localDepartureDate: string | Date;
  disrupted: boolean;
  airline: string;
}

export type ClaimType = 'cancelled' | 'delayed' | 'overbooked';

export interface IClaimEnquiry {
  id: number;
  claimType: ClaimType;
  claimId: number | null;
  createdAt: string;
  uuid: string;
  flights: IClaimEnquiryFlight[];
}

export class ClaimEnquiryFlight implements IClaimEnquiryFlight {
  @Expose() id: number;
  @Expose() departureAirportCode: string;
  @Expose() departureCity: string;
  @Expose() arrivalAirportCode: string;
  @Expose() arrivalCity: string;
  @Expose() localDepartureDate: Date;
  @Expose() disrupted: boolean;
  @Expose() airline: string;
}

export class ClaimEnquiry implements IClaimEnquiry {
  @Expose() id: number;
  @Expose() createdAt: string;
  @Expose() claimType: ClaimType;
  @Expose() claimId: number | null;
  @Expose() uuid: string;

  @Type(() => ClaimEnquiryFlight)
  @Expose()
  flights: ClaimEnquiryFlight[];

  @Expose()
  get claimOrEnquiryUrl(): string {
    return this.claimId !== null
      ? `${getWebappClaimUrl()}${this.claimId}`
      : `${getWebappUrl()}/claim_enquiries/${this.uuid}`;
  }

  @Expose()
  get disruptedFlight(): ClaimEnquiryFlight {
    return this.flights.find((flight) => flight.disrupted) || this.flights[0];
  }
}

export function transformToClaimEnquiryClass(
  claimEnquiry: IClaimEnquiry,
): ClaimEnquiry {
  return plainToClass(ClaimEnquiry, claimEnquiry);
}
