import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';

import { useMyProfile } from './userProfiles/useFetchMyProfile/useFetchMyProfile';

export const useProtectedQuery = <TData = unknown, TSelectedData = TData>(
  params: UseQueryOptions<TData, Error, TSelectedData> & {
    onSuccess?: () => void;
  },
): UseQueryResult<TSelectedData> => {
  const { userProfile } = useMyProfile();

  const paramEnabled = Object.keys(params).includes('enabled')
    ? params.enabled
    : true;

  const { onSuccess, ...queryParams } = params;

  const enhancedQueryParams: UseQueryOptions<TData, Error, TSelectedData> = {
    ...queryParams,
    enabled: paramEnabled && Boolean(userProfile),
  };

  const queryResult = useQuery<TData, Error, TSelectedData>(
    enhancedQueryParams,
  );

  if (queryResult.isSuccess && onSuccess) {
    onSuccess();
  }

  return queryResult;
};
