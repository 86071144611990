import { Expose, Type, plainToClass } from 'class-transformer';

import { Benefits } from './userPolicy/Benefits';
import { Coverage } from './userPolicy/Coverage';
import { Dashboard } from './userPolicy/Dashboard';
import { Perks } from './userPolicy/Perks';
import { ProductName, TripTier, MembershipTier } from '@airhelp/plus';

class MembershipPolicy {
  @Expose() active: boolean;
  @Expose() autoRenewal: boolean;
  @Expose() canceled: boolean;
  @Expose() displayName: string;
  @Expose() endDate: string;
  @Expose() fromCustomer: boolean;
  @Expose() onGracePeriod: boolean;
  @Expose() productName: ProductName;
  @Expose() startDate: string;
  @Expose() tier: MembershipTier;

  @Expose()
  get isEssential(): boolean {
    return this.tier === MembershipTier.ESSENTIAL;
  }

  @Expose()
  get isComplete(): boolean {
    return this.tier === MembershipTier.COMPLETE;
  }

  @Expose()
  get showPaymentManagement(): boolean {
    return this.fromCustomer && this.active && !this.canceled;
  }
}
class TripPolicy {
  @Expose() active: boolean;
  @Expose() partnerSlug: string;
  @Expose() tier: TripTier;

  @Expose()
  get isEssential(): boolean {
    return this.tier === TripTier.ESSENTIAL;
  }

  @Expose()
  get isComplete(): boolean {
    return this.tier === TripTier.COMPLETE;
  }
}

export class UserPolicy {
  @Expose()
  @Type(() => MembershipPolicy)
  membership: MembershipPolicy | null;

  @Expose()
  @Type(() => TripPolicy)
  trip: TripPolicy | null;

  @Expose()
  @Type(() => Benefits)
  benefits: Benefits | null;

  @Expose()
  @Type(() => Perks)
  perks: Perks;

  @Expose()
  @Type(() => Coverage)
  coverage: Coverage;

  @Expose()
  @Type(() => Dashboard)
  dashboard: Dashboard;

  @Expose()
  get hasCoverage(): boolean {
    return Boolean(this.trip || this.membership);
  }

  @Expose()
  get hasActiveCoverage(): boolean {
    return Boolean(
      this.hasCoverage && (this.membership?.active || this.trip?.active),
    );
  }

  @Expose()
  get hasCompleteCoverage(): boolean {
    return Boolean(this.membership?.isComplete || this.trip?.isComplete);
  }
}

export function transformToUserPolicyClass(userPolicy: unknown): UserPolicy {
  return plainToClass(UserPolicy, userPolicy);
}
