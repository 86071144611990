import type React from 'react';

import { type Offer } from 'models/Offers';
import { type PaymentDetails } from 'stores/preferences/stripe';
import { type Order } from 'models/Order';

export enum CheckoutActionTypes {
  UPDATE = 'UPDATE',
  RESET = 'RESET',
  LOAD = 'LOAD',
}

export interface CheckoutData {
  promoCodeDetails: PaymentDetails | null;
  productDetails: Offer | null;
  promoCode: string | null;
  paymentMethod: string | null;
  brand: string | null;
  order: Order | null;
  transactionId: string | null;
}

export interface Action {
  type: CheckoutActionTypes;
  payload?: Partial<CheckoutData>;
}

export const initialCheckoutState: CheckoutData = {
  promoCodeDetails: null,
  productDetails: null,
  promoCode: null,
  paymentMethod: null,
  brand: null,
  order: null,
  transactionId: null,
};

export const LOCAL_STORAGE_KEY = 'ahplus-checkout-tracking-state';

const checkoutDataReducer: React.Reducer<CheckoutData, Action> = (
  checkoutData,
  action,
) => {
  const serializeState = (state: CheckoutData) => {
    try {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    } catch (e) {
      // cannot access local storage
    }
  };

  const update = (updateData: Partial<CheckoutData>) => {
    const newState = { ...checkoutData, ...updateData };

    serializeState(newState);

    return newState;
  };

  const reset = () => {
    const newState = { ...initialCheckoutState };

    serializeState(newState);

    return newState;
  };

  switch (action.type) {
    case CheckoutActionTypes.UPDATE:
      return update(action.payload as Partial<CheckoutData>);
    case CheckoutActionTypes.RESET:
      return reset();
    case CheckoutActionTypes.LOAD:
      try {
        const loadedData = localStorage.getItem(LOCAL_STORAGE_KEY);
        const deserialisedData = loadedData ? JSON.parse(loadedData) : null;

        return deserialisedData;
      } catch (e) {
        // cannot access local storage

        return reset();
      }

    default:
      return { ...checkoutData };
  }
};

export default checkoutDataReducer;
