import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Flex, useDisclosure, Heading } from '@chakra-ui/react';

import esimHeroSmall from 'assets/images/perks/hero-esim-small.png';
import esimHero from 'assets/images/perks/hero-esim.png';
import esimHeroMobile from 'assets/images/perks/hero-esim-mobile@2.png';
import carRentalHero from 'assets/images/perks/hero-car-rental.png';
import carRentalHeroSmall from 'assets/images/perks/hero-car-rental-small.png';
import carRentalHeroMobile from 'assets/images/perks/hero-car-rental-mobile@2.png';
import airportTransferHero from 'assets/images/perks/hero-airport-transfer.png';
import airportTransfersHeroSmall from 'assets/images/perks/hero-airport-transfer-small.png';
import airportTransferHeroMobile from 'assets/images/perks/hero-airport-transfer-mobile@2.png';
import fastTrackHero from 'assets/images/perks/hero-fast-track.png';
import fastTrackHeroMobile from 'assets/images/perks/hero-fast-track-mobile@2.png';
import fastTrackHeroSmall from 'assets/images/perks/hero-fast-track-small.png';
import loungePassHero from 'assets/images/perks/hero-lounge-pass.png';
import loungePassHeroMobile from 'assets/images/perks/hero-lounge-pass-mobile@2.png';
import loungePassHeroSmall from 'assets/images/perks/hero-lounge-pass-small.png';
import airaloLogo from 'assets/images/perks/logo-airalo.svg';
import rentalCarsLogo from 'assets/images/perks/logo-rentalcars.svg';
import suntransfersLogo from 'assets/images/perks/logo-suntransfers.svg';
import highPassLogo from 'assets/images/perks/logo-high-pass.svg';
import loungePassLogo from 'assets/images/perks/logo-lounge-pass.svg';
import { type UserPolicy } from 'models/UserPolicy';

import PerkBanner from './PerkBanner';
import PerkDrawer from './PerkDrawer';
import PerkCarousel from './PerkCarousel';
import AirportTransfer from './PerkDrawer/ServiceContent/AirportTransfer';
import CarRental from './PerkDrawer/ServiceContent/CarRental';
import ESim from './PerkDrawer/ServiceContent/ESim';
import FastTrack from './PerkDrawer/ServiceContent/FastTrack';
import LoungePass from './PerkDrawer/ServiceContent/LoungePass';
import UpsellSmart from '../UpsellSmart';

import _ from 'lodash';
import { useFeatureFlags } from '@airhelp/plus/react';
import { PerkServicesConfig, Services } from 'types/perks';

interface IComponent {
  userPolicy: UserPolicy;
  showUpsellBanner: boolean;
  perkServices: Services[];
}

const PerkServices: React.FC<IComponent> = ({
  userPolicy,
  showUpsellBanner,
  perkServices,
}) => {
  const { t } = useTranslation();
  const { data: featureFlags } = useFeatureFlags();
  const { esim, carRental, airportTransfer, fastTrack, loungePass } =
    userPolicy.perks;

  const eSimDisclosureHook = useDisclosure({ id: 'ESim' });
  const carRentalDisclosureHook = useDisclosure({ id: 'CarRental' });
  const airportTransferDisclosureHook = useDisclosure({
    id: 'AirportTransfer',
  });
  const fastTrackDisclosureHook = useDisclosure({ id: 'FastTrack' });
  const loungePasskDisclosureHook = useDisclosure({ id: 'LoungePass' });

  const config: PerkServicesConfig = {
    loungePass:
      featureFlags?.lounge_pass_perk && loungePass
        ? {
            disclosureHook: loungePasskDisclosureHook,
            drawerContent: (
              <LoungePass
                discount={loungePass.discount}
                imageSrcMobile={loungePassHeroMobile}
                imageText={t('dashboard.perks.lounge_pass.lounge_pass')}
              />
            ),
            description: t('dashboard.perks.lounge_pass.relax_before'),
            imageText: t('dashboard.perks.lounge_pass.lounge_pass'),
            imageSrc: loungePassHero,
            imageSmallSrc: loungePassHeroSmall,
            imageSrcMobile: loungePassHeroMobile,
            logoSrc: loungePassLogo,
            discount: loungePass.discount,
            service: 'lounge-pass',
            provider: 'collinson_loungepass',
          }
        : null,
    fastTrack: fastTrack && {
      disclosureHook: fastTrackDisclosureHook,
      drawerContent: (
        <FastTrack
          code={fastTrack.code}
          discount={fastTrack.discount}
          imageText={t('dashboard.perks.fast_track.fast_track')}
          imageSrcMobile={fastTrackHeroMobile}
        />
      ),
      imageTextColor: 'greyscale.100',
      description: t('dashboard.perks.fast_track.queue_less'),
      imageText: t('dashboard.perks.fast_track.fast_track'),
      imageSrc: fastTrackHero,
      imageSmallSrc: fastTrackHeroSmall,
      imageSrcMobile: fastTrackHeroMobile,
      logoSrc: highPassLogo,
      discount: fastTrack.discount,
      service: 'fast-track',
      provider: 'highpass_fasttrack',
    },
    esim: esim && {
      disclosureHook: eSimDisclosureHook,
      drawerContent: (
        <ESim
          code={esim.code}
          discount={esim.discount}
          imageText={t('dashboard.perks.esim.esim_cards')}
          imageSrcMobile={esimHeroMobile}
        />
      ),
      description: t('dashboard.perks.esim.global_connectivity'),
      imageText: t('dashboard.perks.esim.esim_cards'),
      imageSrc: esimHero,
      imageSmallSrc: esimHeroSmall,
      imageSrcMobile: esimHeroMobile,
      logoSrc: airaloLogo,
      discount: esim.discount,
      service: 'esim',
      provider: 'airalo',
    },
    carRental: carRental && {
      disclosureHook: carRentalDisclosureHook,
      drawerContent: (
        <CarRental
          code={carRental.code}
          discount={carRental.discount}
          imageText={t('dashboard.perks.car_rentals.car_rental')}
          imageSrcMobile={carRentalHeroMobile}
        />
      ),
      description: t('dashboard.perks.car_rentals.great_value_wheels'),
      imageText: t('dashboard.perks.car_rentals.car_rental'),
      imageSrc: carRentalHero,
      imageSmallSrc: carRentalHeroSmall,
      imageSrcMobile: carRentalHeroMobile,
      logoSrc: rentalCarsLogo,
      discount: carRental.discount,
      service: 'car-rental',
      provider: 'rentalcars',
    },
    airportTransfer: airportTransfer && {
      disclosureHook: airportTransferDisclosureHook,
      drawerContent: (
        <AirportTransfer
          imageText={t('dashboard.perks.airport_transfer.airport_transfers')}
          imageSrcMobile={airportTransferHeroMobile}
        />
      ),
      description: t('dashboard.perks.airport_transfer.low_cost_pick_ups'),
      imageText: t('dashboard.perks.airport_transfer.airport_transfers'),
      imageSrc: airportTransferHero,
      imageSmallSrc: airportTransfersHeroSmall,
      imageSrcMobile: airportTransferHeroMobile,
      logoSrc: suntransfersLogo,
      discount: null,
      service: 'airport-transfer',
      provider: 'suntransfers',
    },
  };

  const noOfAvailablePerks = Object.keys(config).filter((perk) => {
    return (
      Boolean(userPolicy.perks[perk]) && perkServices.includes(perk as Services)
    );
  }).length;

  return (
    <>
      <Heading variant="h5" alignSelf="flex-start" order={3} mb={-8}>
        {t('dashboard.traveling_abroad_we_got_something')}
      </Heading>
      {noOfAvailablePerks === 1 ? (
        <Flex
          order={4}
          gap={{ base: 2, md: 4 }}
          alignItems="stretch"
          overflowX="auto"
          sx={{
            scrollbarWidth: 'none',
            msOverflowStyle: '-ms-autohiding-scrollbar',
            '&::-webkit-scrollbar': { display: 'none' },
          }}
          flexDirection={{
            base: showUpsellBanner ? 'column' : 'row',
            md: 'row',
          }}
        >
          {Object.values(config).map(
            (perk) =>
              perk && (
                <Fragment key={`perk-service-${perk.service}`}>
                  <PerkBanner
                    imageSrc={perk.imageSmallSrc}
                    imageText={perk.imageText}
                    logoSrc={perk.logoSrc}
                    description={perk.description}
                    onClick={perk.disclosureHook.onOpen}
                    discount={perk.discount}
                    service={perk.service}
                    provider={perk.provider}
                    key={`${perk.service}-perk-banner`}
                  />
                  <PerkDrawer
                    isOpen={perk.disclosureHook.isOpen}
                    imageSrc={perk.imageSrc}
                    imageSrcMobile={perk.imageSrcMobile}
                    onClose={perk.disclosureHook.onClose}
                    content={perk.drawerContent}
                    service={perk.service}
                    provider={perk.provider}
                    key={`${perk.service}-perk-drawer`}
                  />
                </Fragment>
              ),
          )}
          {showUpsellBanner ? <UpsellSmart /> : null}
        </Flex>
      ) : (
        <PerkCarousel config={config} noOfAvailablePerks={noOfAvailablePerks} />
      )}
    </>
  );
};

export default PerkServices;
